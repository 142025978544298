@import "ri-global.scss";
.taxonomy-toolbar {
  display: flex;
  width: auto;
  align-items: center;
  justify-content: space-between;
  margin: 12px 12px 12px 0;
}

.taxonomy-toolbar-buttons {
  display: flex;
}

.taxonomy-toolbar-buttons button {
  margin-right: 12px;
}

.taxonomy-parent-input {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.taxonomy-parent-input-validation {
  margin: 10px 0;
  min-height: 70px;

  .glyphicon-ok-sign,
  .glyphicon-warning-sign {
    padding-right: 10px;
  }

  .alert {
    width: 659px;
    margin-left: 88px;
  }
}

.taxonomy-name-input {
  display: flex;
  height: 120px;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
}

.taxonomy-name-input > textarea {
  padding: 6px;
}

.taxonomy-name-input > textarea,
.parent-suggestion-input {
  width: 500px;
}

div.taxonomy-parent-input > div > div > div > div.rbt-input.form-control.rbt-input-multi > div > div > div {
  width: 500px;
}

.taxonomy-input-label {
  text-align: left;
  font-size: 16px;
  width: 140px;
  margin-right: 24px;
}

.taxonomy-input-field {
  width: 225px;
}

.taxonomy-name-input > span {
  padding-top: 12px;
}
