@import "ri-global.scss";

.radio-btn-wrapper {
  width: 25px;
  height: 25px;
  display: inline-block;
  position: relative;
}

.radio-btn-wrapper [type="radio"]:not(:checked),
.radio-btn-wrapper [type="radio"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  height: 100%;
  width: 100%;
  margin: 0 0 0 0;
}

.radio-btn-wrapper [type="radio"]:not(:checked) + span,
.radio-btn-wrapper [type="radio"]:checked + span {
  position: relative;
  padding-left: 100%;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  line-height: 25px;
  font-size: 1rem;
  -webkit-transition: .28s ease;
  transition: .28s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-btn-wrapper [type="radio"] + span:before,
.radio-btn-wrapper [type="radio"] + span:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  -webkit-transition: .28s ease;
  transition: .28s ease;
}

.radio-btn-wrapper [type="radio"]:not(:checked) + span:before,
.radio-btn-wrapper [type="radio"]:not(:checked) + span:after,
.radio-btn-wrapper [type="radio"]:checked + span:before,
.radio-btn-wrapper [type="radio"]:checked + span:after,
.radio-btn-wrapper [type="radio"].with-gap:checked + span:before,
.radio-btn-wrapper [type="radio"].with-gap:checked + span:after {
  border-radius: 50%;
}

.radio-btn-wrapper [type="radio"]:not(:checked) + span:before,
.radio-btn-wrapper [type="radio"]:not(:checked) + span:after {
  border: 2px solid $dark-grey;
}

.radio-btn-wrapper:hover [type="radio"]:not(:checked) + span:before {
  border-color: $primary-color;
}

.radio-btn-wrapper [type="radio"]:not(:checked) + span:after {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radio-btn-wrapper [type="radio"]:checked + span:before {
  border: 2px solid transparent;
}

.radio-btn-wrapper [type="radio"]:checked + span:after,
.radio-btn-wrapper [type="radio"].with-gap:checked + span:before,
.radio-btn-wrapper [type="radio"].with-gap:checked + span:after {
  border: 2px solid $primary-color;
}

.radio-btn-wrapper [type="radio"]:checked + span:after,
.radio-btn-wrapper [type="radio"].with-gap:checked + span:after {
  background-color: $primary-color;
}

.radio-btn-wrapper [type="radio"]:checked + span:after {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.radio-btn-wrapper [type="radio"].with-gap:checked + span:after {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

.radio-btn-wrapper [type="radio"].tabbed:focus + span:before {
  -webkit-box-shadow: 0 0 0 10px rgba(black, 0.1);
  box-shadow: 0 0 0 10px rgba(black, 0.1);
}

.radio-btn-wrapper [type="radio"].with-gap:disabled:checked + span:before {
  border: 2px solid rgba(black, 0.42);
}

.radio-btn-wrapper [type="radio"].with-gap:disabled:checked + span:after {
  border: none;
  background-color: rgba(black, 0.42);
}

.radio-btn-wrapper [type="radio"]:disabled:not(:checked) + span:before,
.radio-btn-wrapper [type="radio"]:disabled:checked + span:before {
  background-color: transparent;
  border-color: rgba(black, 0.42);
}

.radio-btn-wrapper [type="radio"]:disabled + span {
  color: rgba(black, 0.42);
}

.radio-btn-wrapper [type="radio"]:disabled:not(:checked) + span:before {
  border-color: rgba(black, 0.42);
}

.radio-btn-wrapper [type="radio"]:disabled:checked + span:after {
  background-color: rgba(black, 0.42);
  border-color: #949494;
}
