@import "ri-global.scss";
.etl-stats-toolbar {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin: 6px;
}

.etl-stats-totals {
  display: flex;
  align-items: center;
  height: 100px;
  border-bottom: 1px solid $primary-color;
}

.etl-stats-totals > div {
  display: flex;
  flex-direction: column;
  min-width: 175px;
  max-height: 100%;
  padding: 6px 0 6px 12px;
  margin: 0;
  font-size: 20px;
  border-right: 2px solid $primary-color;
}

.etl-stats-totals > div > span {
  font-size: 18px;
  margin-top: 6px;
}

#stats-task-completed {
  color: lightgreen;
}

#stats-task-failed {
  color: #FF0033;
}

.etl-stats-filters {
  display: flex;
  align-items: center;
  height: 75px;
  border-bottom: 1px solid $primary-color;
}

.etl-stats-filters > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 48px;
}

.etl-stats-filters > div:first-of-type {
  margin-left: 24px;
}

.etl-stats-filters > div#etl-stats-text-filter {
  width: 350px;
  align-items: center;
  justify-content: space-between;
}

#etl-stats-text-filter select {
  height: 30px;
}

#etl-stats-text-filter input {
  margin-left: 12px;
  height: 30px;
  width: 75%;
}

#etl-stats-text-filter button {
  width: 75px;
}
