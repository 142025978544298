@import "ri-global.scss";
.delivery-logs-table tr.success,
.delivery-logs-table tr.info,
.delivery-logs-table tr.danger,
.delivery-logs-table tr.warning,
.delivery-logs-table thead tr {
  border-left: 5px solid;
  border-radius: 3px;
}

.delivery-logs-table tr.danger {
  border-left-color: red;
}

.delivery-logs-table tr.success {
  border-left-color: green;
}

.delivery-logs-table tr.info {
  border-left-color: #004c82;
}

.delivery-logs-table tr.warning {
  border-left-color: orange;
}

.delivery-logs-table thead tr {
  border-left-color: $dark-grey;
}

.delivery-logs-table .table>tbody>tr.success>td,
.delivery-logs-table .table>tbody>tr.info>td {
  background-color: transparent;
}

.delivery-logs-table .table>tbody>tr.success:hover>td {
  background-color: #d0e9c6;
}

.delivery-logs-table .table>tbody>tr.info:hover>td {
  background-color: #c4e3f3;
}

.delivery-logs-table th {
  text-transform: capitalize;
}

.delivery-logs-page {
  display: flex;
  flex-direction: column;
  margin: 1% 2%;
}

.delivery-logs-page .btn-toolbar {
  margin-bottom: 24px;
}

