@import "ri-global.scss";
.node {
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 3px;
}

.node:hover {
  background-color: rgb(220, 245, 243);
  cursor: pointer;
}

.info,
.node {
  padding: 2px 10px 2px 5px;
  font: 14px Roboto, sans-serif, Helvetica, Arial, sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container-fluid {
  width: auto;
}

.tree-view_arrow {
  -moz-transition: all 0.1s;
  -o-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}

.tree-view_arrow-empty {
  color: yellow;
}

.search-root .overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  z-index: 50;
  width: calc(100% + 20px);
  height: calc(100% + 60px);
  margin: -18px 0px 0px -10px;
  opacity: 0;
  -webkit-transition: opacity 0.8s ease-in-out 0.3;
  -moz-transition: opacity 0.8s ease-in-out 0.3;
  transition: opacity 0.8s ease-in-out 0.3;
}
#search-qc-wrapper {
  z-index: 10;
  position: relative;
}

.query-tree-overlay-visible {
  display: block;
  opacity: 0.5 !important;
}

.query-tree-overlay-hidden {
  display: none;
  opacity: 0 !important;
}
