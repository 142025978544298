@import "ri-global.scss";
.step-panel {
  border: none;
  box-shadow: none;
  margin-left: 15px;
  position: relative;
}

.panel-group .step-panel {
  border-radius: 0px;
  border: 0px solid black;
}

.panel-group .panel.step-panel + .panel.step-panel {
  margin-top: 12px;
}

.step-panel .panel-heading {
  background-color: white;
  border: none;
  box-shadow: none;
  position: relative;
  line-height: 18px;
  font-size: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 30px;
  color: $dark-grey;
  transition: all 0.3s ease-in-out;
}

.step-panel.complete .panel-heading {
  color: $primary-color;
}

.step-panel.active.editable .panel-heading {
  color: $dark-grey;
}

.step-panel.failed.active .panel-heading,
.step-panel.failed.active.editable .panel-heading,
.step-panel.failed .panel-heading {
  color: $secondary-color;
}


.panel-group .step-panel .panel-heading + .panel-collapse > .panel-body {
  border-top: none;
}

.step-panel .panel-heading a:hover {
  color: inherit;
}
.step-panel.disabled .panel-heading a:focus,
.step-panel.disabled .panel-heading a:hover {
  cursor: not-allowed;
  text-decoration: none;
}

.step-panel .step-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  position: absolute;
  left: -15px;
  top: 0px;
  border: 1px solid $dark-grey;
  color: $dark-grey;
  background-color: white;
  transition: all 0.3s ease-in-out;
}

.step-panel.complete .step-icon {
  color: white;
  background-color: $primary-color;
  border-color: $primary-color;
}

.step-panel.active.editable .step-icon {
  color: white;
  background-color: $dark-grey;
  border-color: $dark-grey;
}

.step-panel.active.failed .step-icon,
.step-panel.active.failed.editable .step-icon,
.step-panel.failed .step-icon {
  color: white;
  background-color: $secondary-color;
  border-color: $secondary-color;
}

.step-panel.pending {
  opacity: 0.5;
}

.step-panel.pending.failed,
.step-panel.pending.active {
  opacity: 1;
}

.step-panel::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 2px;
  border-left: 1px solid $font-color;
  top: 15px;
}

.step-panel.complete::before {
  border-left-color: $primary-color;
}

.step-panel:last-child::before {
  border-left-color: transparent;
}

.step-panel-summary .step-summary-label {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  position: relative;
  left: -15px;
  top: 0px;
  padding: 0px 6px;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  border: 1px solid $font-color;
  background-color: white;
  color: $dark-grey;
}

.step-panel-summary.pending {
  opacity: 0.5;
}

.step-panel-summary.complete .step-summary-label {
  border: 1px solid $primary-color;
  background-color: white;
  color: $primary-color;
}

.step-panel .step-summary-action-bar {
  display: inline-block;
  margin-left: -3px;
}

.step-panel.step-panel-summary .panel-heading {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  height: 30px;
}

.step-panel .panel-title,
.step-panel .header-suffix {
  display: inline-block;
}

.step-panel .header-suffix .last-updated {
  padding: 6px;
  height: 12px;
  color: $grey;
  font-size: 10px;
  font-weight: bold;
  line-height: 12px;
}
