@import "ri-global.scss";
.GroupsPage__container {
  text-align: center;
}

.GroupsPage__ThreeWayCheckBox {
  margin: 10px 0;
  width: 16px;
  min-width: 16px;
}

.admin-table {
  width: 100%;
  border-collapse: collapse;
}

.admin-table tr {
  vertical-align: middle;
  padding: 5px 5px;
  border-bottom: 1px solid #ddd;
}

.groupActive {
  display: flex;
  justify-content: center;
}
