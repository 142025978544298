@import "ri-global.scss";
.etl-stats-container {
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.etl-stats-widget-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.etl-stats-widget-row {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
