@import "ri-global.scss";
.group-edit-page {
  height: 100%;
  width: 90%;
  margin: auto;
  min-height: 700px;
}

#group-edit-tabs > ul > li.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
