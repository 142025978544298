@import "ri-global.scss";
.monitoring-info {
  background-color: $light-primary-color;
  display: flex;
  justify-content: center;
  p {
    margin: 8px;
    font-size: 16px;
    color: black;
  }
}
