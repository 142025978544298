@import "ri-global.scss";
.group-edit-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100px;
  padding: 12px;
}

.group-edit-toolbar-left {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 500px;
}

.group-edit-info {
  width: 100%;
  height: 75px;
  margin-left: 12px;
  padding-left: 6px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.group-edit-info > .label {
  width: 65px;
  height: 20px;

  border-radius: 12px;
  font-size: 12px;
  padding: 4px 6px;
  color: #fff;
  background-color: #606060;

  display: inline-block;
  text-align: center;
  font-weight: bold;
}

.group-edit-info > .label#inactive {
  background-color: #dedede;
}

.group-edit-info span#group-edit-name {
  font-size: 24px;
  font-weight: 300;
  margin: 3px 0;
}

.group-edit-toolbar-buttons button {
  margin: 12px;
  border-radius: 0;
}

.group-edit-toolbar-buttons button#group-edit-deactivate {
  width: 124px;
  background-color: $secondary-color;
}

.group-edit-toolbar-buttons button#group-edit-delete {
  width: 140px;
  background-color: $secondary-color;
}
