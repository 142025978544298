@import "ri-global.scss";
.group-edit-reports-tab,
.group-edit-permissions-tab {
  margin-top: 12px;
  max-height: 90%;
}

.group-edit-permissions-tab > div,
.group-edit-permissions-tab > div > div {
  max-height: 500px;
}

.toggle-group-product {
  width: 100%;
  height: 50px;
  padding-left: 12px;

  display: flex;
  align-items: center;
}

.toggle-group-product .CheckBox .label {
  font-size: 16px;
}

/* Custom Reports tab */

#group-edit-tabs-pane-CUSTOM_REPORTS_TAB div.productHeader {
  background: inherit;
}

#group-edit-tabs-pane-CUSTOM_REPORTS_TAB div span.productTitle {
  font-size: 16px;
  margin-bottom: 12px;
  background-color: #f7f7f7;
}

#group-edit-tabs-pane-CUSTOM_REPORTS_TAB div.flex.CheckBoxMultiSelect.alignItemsCenter {
  margin-bottom: 12px;
}

/* Admin tools tab */

#group-edit-tabs-pane-ADMIN_TOOLS > div {
  min-height: 200px;
}

#group-edit-tabs-pane-ADMIN_TOOLS > div > span {
  font-size: 18px;
  margin-left: 28px;
  line-height: 92px;
}
