@import "ri-global.scss";
.deliverables-categories-table {
  max-height: 300px;
  overflow: auto;

  margin: 10px 0 40px 0;

  .deliverables-categories-table-info {
    margin-top: -20px;
  }

  .deliverables-categories-table-category-path {
    max-width: 320px;
  }

  .glyphicon-ok-circle {
    color: $green-color;
  }

  .glyphicon-minus-sign {
    color: $yellow-color;
  }
}
