@import "ri-global.scss";
.FeedList .table {
  text-align: center;
}

.FeedList .ReactVirtualized__Table__row {
  vertical-align: middle;
  padding: 5px 5px;
  border-bottom: 1px solid #ddd;
}
.FeedList .table td:first-child {
  width: 16px;
}
.FeedList .table tr:first-child td {
  border-bottom: none;
}
.FeedList .table .groupLogo {
  width: 60px;
}
.FeedList .table .groupLogo > img {
  max-width: 50px;
  max-height: 50px;
  text-align: center;
  border-radius: 5px;
  border: 2px solid #ddd;
  border-radius: 5px;
}
.FeedList .table .tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.FeedList__searchContainer {
  display: flex;
  justify-content: space-between;
}

.FeedList__search {
  width: 49%;
}

.FeedList__search__div {
  width: 100%;
}

.feed-status-active {
  font-weight: bolder;
  color: $primary-color
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
  margin: 0;
}
.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .2s;
  transition: .2s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .2s;
  transition: .2s;
}

input:checked + .slider {
  background-color: $secondary-color;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
input:focus + .slider {
  box-shadow: 0 0 1px $secondary-color;
}

.FeedList .today {
  font-weight: bold;
}
