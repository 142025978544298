@import "ri-global.scss";
.etl-stats-widget {
  flex: 1;
  border: 1px solid $primary-color;
  height: 550px;
  margin: 6px;
  overflow-y: auto;
}

.etl-stats-widget h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 12px;
  padding-left: 6px;
}

.etl-stats-widget table {
  width: 100%;
  overflow-y: scroll;
}

.etl-stats-widget tbody {
  overflow-y: scroll;
}

.etl-stats-widget th {
  padding: 6px;
  font-size: 14px;
  text-align: center;
  border: 1px solid lightgrey;
}

.etl-stats-widget tr > td {
  min-width: 100px;
  border: 1px solid lightgray;
}

.etl-stats-widget tr > td:first-of-type {
  padding-left: 6px;
  border-left: none;
}

.etl-stats-widget tr > td:last-of-type {
  border-right: none;
}

.etl-stats-chart-buttons {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid lightgray;
}

.etl-stats-chart-buttons button {
  width: 125px;
  height: 75%;
  color: white;
  font-weight: bold;
  background-color: $primary-color;
  border-color: $primary-color;
}

.etl-stats-chart-buttons button:hover {
  color: white;
  background-color: $primary-color;
}

.etl-stats-chart-widget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.etl-stats-chart-widget h3 {
  width: 100%;
  margin-left: 0;
  padding: 0 0 12px 12px;
  border-bottom: 1px solid lightgray;
}
