@import "ri-global.scss";
.sb-form .form-box {
  border: 2px solid $light-grey;
  padding: 12px 12px;
  margin-bottom: 12px;
}

.sb-form .form-control {
  border: none;
  border-radius: 0px;
  box-shadow: none;
  transition: border-color 0.3s ease-in-out;
  border-bottom: 1px solid $light-grey;
  padding: 6px 0px;
}

.sb-form .input-group-addon {
  background: transparent;
  border: none;
  padding: 6px;
  font-weight: bold;
}

.sb-form input[type='number']::-webkit-inner-spin-button,
.sb-form input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sb-form .form-control:focus,
.sb-form .form-control.focus {
  border-bottom: 1px solid $primary-color;
}

.sb-form .has-error .form-control,
.sb-form .has-error .form-control:focus {
  border-bottom: 1px solid $secondary-color;
  box-shadow: none;
}

.sb-form .field-name,
.sb-form label.control-label {
  color: $grey;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
}

.sb-form .sb-form-footer {
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

.sb-form .dropdown {
  margin: 3px 6px 3px 6px;
  position: relative;
  top: -1px;
}

.sb-form button.dropdown-toggle.read-only[disabled] {
  background-color: inherit;
  color: inherit;
  font-weight: inherit;
  opacity: inherit;
}

.sb-form .single-date-input-btn,
.sb-form .dropdown .dropdown-toggle.btn-default {
  border-radius: 0px;
  padding: 3px 6px;
  height: 30px;
  border: 1px solid $light-grey;
  transition: border-color 0.3s ease-in-out
}

.sb-form .portal-query-builder {
  .dropdown .dropdown-toggle.btn-default,
  .qb-rule .rule-selections-wrapper .dropdown {
    height: 24px;
    margin: 0px 2px 0px 0px;
  }
}

.sb-form .single-date-input-btn {
  height: 54px;
}

.sb-form .dropdown+.dropdown {
  margin-left: 0px;
}

.sb-form .single-date-input-btn:hover,
.sb-form .dropdown>.dropdown-toggle.btn-default:hover,
.sb-form .dropdown>.dropdown-toggle.btn-default:active:hover,
.sb-form .dropdown>.dropdown-toggle.btn-default:hover {
  border-color: $dark-grey;
  color: inherit;
  background-color: inherit;
  box-shadow: none;
}

.sb-form .single-date-input-btn:focus,
.sb-form .dropdown>.dropdown-toggle.btn-default:focus,
.sb-form .open>.dropdown-toggle.btn-default,
.sb-form .open>.dropdown-toggle.btn-default:focus,
.sb-form .open>.dropdown-toggle.btn-default:hover {
  border-color: $primary-color;
  color: inherit;
  background-color: inherit;
  box-shadow: none;
}

.sb-form .dropdown .dropdown-menu {
  border-radius: 0px;
  margin-top: -1px;
  border: 1px solid $primary-color;
}

.sb-form .has-error .help-block,
.sb-form .has-error .form-control-feedback {
  color: $secondary-color;
}

.has-feedback label ~ .form-control-feedback {
  top: 18px;
}

.sb-form-popup {
  border-radius: 0px;
  border-color: $primary-color;
  padding: 0px;
}

.sb-form-popup .popover-title {
  background-color: $primary-color;
  color: white;
  border-radius: 0px;
  border: none;
}
.sb-form-popup.bottom .arrow {
  border-bottom-color: $primary-color;
}

.sb-form-popup.bottom .arrow:after {
  top: 1px;
  border-bottom-color: $primary-color;
}


.sb-form .form-action-buttons .btn-default {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 18px;
  margin: 0 18px;
  border-radius: 0;
}

.sb-form .btn-ok,
.sb-form .btn-ok.btn-default {
  border: 1px solid $primary-color;
  background-color: $primary-color;
  color: white;
  font-weight: bold;
}

.sb-form .btn-cancel,
.sb-form .btn-cancel.btn-default {
  background-color: white;
  border: 1px solid $grey;
  font-weight: bold;
}

.sb-form .form-action-buttons button.btn-default:hover {
  box-shadow: 0px 4px 8px 4px $light-grey;
}

.sb-form button.btn-small,
.sb-form button.btn-default.btn-small {
  height: 30px;
  line-height: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 12px;
  margin: 0 0 0 12px;
}

.form-action-buttons {
  display: flex;

}

.sb-form .form-control[readonly] {
  background-color: inherit;
}
