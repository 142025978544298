@import "ri-global.scss";
.current-delivery-page .page-message {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 15px;
}

.deadline-date-select {
  z-index: 10001;
}

.edit-deadline-modal .single-date-input-btn {
  text-align: center;
  display: block;
  margin: auto;
  min-width: 105px;
  position: absolute;
  top: 0px;
}
.edit-deadline-modal .deadline-start-input {
  left: 0px;
}

.edit-deadline-modal .deadline-date-input {
  right: 0px;
}

.edit-deadline-modal .date-input-group {
  position: relative;
  height: 54px;
  margin: 24px 54px 36px 54px;
}

.edit-deadline-modal .date-input-group .arrow-right {
  width: calc(100% - 250px);
  border: none;
  border-bottom: 2px solid $grey;
  color: $grey;
  height: 10px;
  margin-top: 18px;
  margin-left: 130px;
  margin-bottom: 24px;
  position: relative;
}

.edit-deadline-modal .date-input-group .arrow-right svg {
  position: absolute;
  right: -20px;
  top: 0px;
  transform: rotateZ(90deg) scale(1.5);
}

.edit-deadline-modal .date-input-group .single-date-input-btn:disabled:hover,
.edit-deadline-modal .date-input-group .single-date-input-btn:disabled {
  background: inherit;
  border: inherit;
  color: $dark-grey;
  opacity: 1;
}

.delivery-summary-panel.step-panel.delayed .panel-heading {
  color: $tertiary-color;
}
.delivery-summary-panel.step-panel.delayed .step-icon {
  color: $tertiary-color;
  border-color: $tertiary-color;
  background-color: white;
}
.delivery-summary-panel.step-panel.complete.delayed .step-icon {
  color: white;
  border-color: $tertiary-color;
  background-color: $tertiary-color;
}

.delivery-summary-panel.step-panel.cancelled .panel-heading {
  color: $secondary-color;
}
.delivery-summary-panel.step-panel.cancelled .step-icon {
  color: $secondary-color;
  border-color: $secondary-color;
  background-color: white;
}
.delivery-summary-panel.step-panel.complete.cancelled .step-icon {
  color: white;
  border-color: $secondary-color;
  background-color: $secondary-color;
}

.delivery-summary-panel.step-panel.pending {
  opacity: 1;
}
.delivery-summary-panel a {
  cursor: default !important;
}

.delivery-summary-panel .edit-deadline-btn {
  margin: 3px 6px;
  font-size: 12px;
  line-height: 14px;
  color: $primary-color;
  cursor: pointer;
}

.delivery-summary-panel .edit-deadline-btn:hover {
  text-decoration: underline;
}

.sb-form.category-cleanup-jobs .ReactVirtualized__Table__row {
  border-bottom: 1px solid #ddd;
}

.sb-form.category-cleanup-jobs .ReactVirtualized__Table__headerRow {
  border-bottom: 1px solid $font-color;
}

.sb-form.category-cleanup-jobs .ReactVirtualized__Table__headerColumn {
  opacity: 0.5;
}

.sb-form.category-cleanup-jobs .ReactVirtualized__Table__rowColumn.cat-job-cell,
.sb-form.category-cleanup-jobs
  .ReactVirtualized__Table__rowColumn.cat-job-cell
  .table-cell {
  display: block;
  position: relative;
  text-align: center;
}

.sb-form.category-cleanup-jobs .category-full-path-cell {
  white-space: normal;
}

/* width */
.sb-form.category-cleanup-jobs ::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.sb-form.category-cleanup-jobs ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.sb-form.category-cleanup-jobs ::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}

/* Handle on hover */
.sb-form.category-cleanup-jobs ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sb-form.category-cleanup-jobs .top-spacing {
  display: none;
}

.sb-form.category-cleanup-jobs .RVT_Container {
  margin-bottom: 30px;
}

.sb-form.category-cleanup-jobs .action-button {
  padding: 3px 6px;
  font-size: 12px;
}

.place-left {
  position: absolute;
  left: 35px;
}

.place-left .link {
  margin-left: 15px;
}
