@import "ri-global.scss";
/* Category table Start*/
.category-config {
  width: calc(100% - 28px);
  margin-left: 28px;
}

.category-config .RVT_Container {
  margin-bottom: 24px;
}

.category-config .category-full-path-cell {
  white-space: normal;
}

.category-config .ReactVirtualized__Table__sortableHeaderColumn {
  outline: none;
  white-space: nowrap;
}
.category-config .ReactVirtualized__Table__row .CheckBox .checked {
  fill: white;
  background-color: $primary-color;
}

.category-config .circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: auto;
}

$wrong-color: #ff4500;
$warning-color: #ffff00;
$ok-color: #90ee90;

.category-config .incorrect-category-status {
  color: $wrong-color;
}

.category-config .circle.red {
  background: $wrong-color;
}

.category-config .circle.green {
  background: $ok-color;
}

.category-config .circle.yellow {
  background: $warning-color;
}

.category-config {
  .category-config-filters {
    padding-top: 10px;

    .glyphicon-filter {
      font-size: 18px;
      padding: 0 8px 0 3px;
      top: 6px;
    }

    .glyphicon-exclamation-sign {
      top: 3px;
      font-size: 16px;
      color: $wrong-color;
      padding-right: 8px;
    }

    .badge {
      margin-left: 5px;
    }


  }
}

/* width */
.category-config ::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.category-config ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.category-config ::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}

/* Handle on hover */
.category-config ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.category-config .RVT__searchContainer .input-search {
  width: 300px;
}

.category-config .RVT__searchContainer .input-search:focus-within {
  border-bottom-color: $primary-color;
}

.category-config .no-category-data-msg {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 10px;
}

/* Category table end */

#dm-tabs-wrapper #bci-config-tabs.portal-tabs {
  margin-left: 28px;
  padding-left: 0px;
  .tab-content {
    box-shadow: none;
    border-top: 2px solid rgba($dark-grey,0.1);
    padding-left: 12px;
    padding-right: 12px;
  }
}

#bci-config-tabs {
  .bt-actions {
    height: 36px;
    button {
      margin-left: 12px;
      height: 36px;
    }
    .btn_custom_bg_red {
      visibility: hidden;
    }
  }
  .brand-tracker:hover {
    border: 2px dotted $primary-color;
    .bt-new-query::before {
      border-bottom: 2px dotted $primary-color;
    }
    &>.bt-actions .btn_custom_bg_red {
      visibility: visible;
    }
  }
  .brand-tracker {
    border: 2px dotted rgba($dark-grey,0.1);
    padding: 12px;
    margin-top: 12px;
    .bt-query-wrapper:hover {
      border: 2px dotted $secondary-color;
      &>.bt-actions .btn_custom_bg_red {
        visibility: visible;
      }
    }
    .bt-query-wrapper {
      border: 2px dotted rgba($dark-grey,0.1);
      padding: 12px;
      margin-top: 12px;
    }
    .bt-new-query {
      text-transform: uppercase;
      font-weight: bold;
      position: relative;
      text-decoration: none;
      left: -20px;
      top: 5px;
      outline: 0px;
    }
    .bt-new-query::before {
      content: '';
      border-bottom: 2px dotted rgba($dark-grey,0.1);
      position: absolute;
      left: -3px;
      height: calc(50% + 3px);
      top: 0px;
      width: 10px;
    }
  }
}

.inc-exc-qt {
  height: 50px;
  button {
    margin-left: 12px;
    height: 36px;
  }
  .empty-qt {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.query-combinator {
  display: block;
  text-align: center;
  color: $primary-color;
  font-weight: bold;
}

.brand-query {
  border: 1px dotted $secondary-color;
  padding: 10px;
  margin: 10px;
}
