@import "ri-global.scss";
.delivery-jobs-page {
  .delivery-jobs-table {
    tbody > tr > td {
      .glyphicon-comment {
        color: $primary-color;
      }
    }

    .sb-table-action-cell .glyphicon-hand-up,
    .sb-table-action-cell .glyphicon-hand-down,
    {
      font-weight: 600;
    }

    // statuses
    .category-job-status{
      text-transform: uppercase;
      font-weight: bold;
    }
    .category-job-status-unscheduled {
      color: $grey;
    }

    .category-job-status-scheduled {
      color: $red-color
    }
    .category-job-status-processing {
      color: $yellow-color;
    }
    .category-job-status-ready {
      color: $blue-color;
    }
    .category-job-status-published {
      color: $green-color;
    }
  }

  .delivery-jobs-publish {
    line-height: 34px;

    .delivery-jobs-publish-status {
      width: 175px;
      margin-left: 10px;
      display: inline-block;

      .delivery-jobs-publish-text {
        cursor: pointer;
      }
    }

    .glyphicon-refresh {
      padding-left: 5px;
    }
  }
}

#delivery-jobs-publish-task-tooltip {
  .tooltip-inner {
    text-align: left;
  }
}
