@import "ri-global.scss";
.copyright {
  position: fixed;
  color: $dark-grey;
  font-size: 13px;
  text-align: center;
  line-height: 30px;
  margin: auto;
  bottom: 6px;
  width: 100%;
  margin-left: -70px;
}

.copyright-post-login {
  position: absolute;
  color: #bbb;
  z-index: 1;
  bottom: 0px;
}
