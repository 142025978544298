@import "ri-global.scss";
.requests-table {
  tbody > tr > td {
    .glyphicon-comment {
      color: $primary-color;
      margin-left: 3px;
    }
  }

  // status column in table
  .requests-status {
    text-transform: uppercase;
    font-weight: bold;
  }

  .requests-status-pending {
    color: $blue-color;
  }
  .requests-status-failed,
  .requests-status-rejected {
    color: $red-color;
  }

  .requests-status-deleted {
    color: $grey;
  }

  .requests-status-processing {
    color: $yellow-color;
  }

  .requests-status-approved {
    color: $green-color;
  }

  .requsts-result-column {
    white-space: pre;
  }
}

#request-payload-cell-tooltip .tooltip-inner {
  max-width: 550px;
  text-align: left;
  white-space: pre-line;
  font-size: 14px;
}
