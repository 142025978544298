@import "ri-global.scss";
.authView {
  display: flex;
  background-image: url(./bg_login.png);
  background-size: cover;
  position: absolute;
  margin-top: 60px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.inputWithBackgroundColor {
  display: flex;
  padding: 0 9px;
  background-color: #eaf4f5;
  /*border-radius: 3px;*/
}

.inputWithBackgroundColor input {
  -webkit-box-shadow: 0 0 0px 1000px #eaf4f5 inset;
}

.authBox {
  display: flex;
  width: 650px;
  background-color: rgba(black, 0.7);
  padding: 20px 20px 10px 20px;
  text-align: center;
}

.authBox input {
  height: 45px;
}

.authBox h1 {
  color: white;
  margin: 0 auto;
  padding-bottom: 40px;
  text-transform: uppercase;
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
}

.loginPage .authBox h1 {
  width: 80%;
}

.authBox p {
  color: white;
}

.authBox > div {
  max-width: 100%;
}

.authBox .subtext {
  color: white;
  font-size: 16px;
  min-width: 370px;
  max-width: 100%;
}

.email,
.keepMeLoggedIn,
.button,
.submit,
.authBox .password {
  margin-bottom: 18px;
}
.email,
.password,
.button,
.submit,
.keepMeLoggedIn {
  min-width: 61%;
}

.authBox .subtext .email,
.authBox .subtext .submit {
  width: 100%;
}

.email,
.password {
  font-size: 20px;
}

.errorMessage,
.successMessage {
  color: red;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
}

.successMessage {
  color: white;
}

.keepMeLoggedIn {
  color: white;
  padding: 6px 0 12px 0;
  font-size: 1em;
  text-align: left;
}

.keepMeLoggedIn .label {
  color: $light-grey;
  font-size: 12px;
  line-height: 14px;
}
.button {
  background-color: rgb(252, 168, 49);
  font-size: 20px;
  cursor: pointer;
}

.submit {
  text-shadow: none;
  background-color: $primary-color;
  font-size: 20px;
  color: white;
  cursor: pointer;
  box-shadow: none;
  border-radius: 0px;
  font-weight: bold;
  border: 0;
}

.submit:focus {
  border-color: $dark-primary-color;
}

.submit.loading,
.submit:hover {
  text-shadow: none;
  background-color: $dark-primary-color;
  border-color: $dark-primary-color;
  font-size: 20px;
  color: white;
  cursor: pointer;
  box-shadow: none;
  border-radius: 0px;
  font-weight: bold;
  border: 0;
}

div.submit {
  cursor: default;
}

.submit :global .react-spinner {
  height: 16px;
  width: 16px;
  margin-top: 26px;
  margin-bottom: 10px;
}

.forgot-link,
.login-link {
  font-size: 1em;
  font-weight: bold;
  color: $secondary-color !important;
}

.contactID {
  color: $primary-color;
}

.footer {
  height: 40px;
}
