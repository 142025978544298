@import "ri-global.scss";
.progress-points {
  display: block;
  position: relative;
  height: 12px;
}
.progress-points .dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  background-color: $background-grey;
  border-color: $light-grey;
  display: inline-block;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;
}

.progress-points .dot.done {
  background-color: $primary-color;
  border-color: $primary-color;
}

.progress-points .dot.error {
  background-color: $secondary-color;
  border-color: $secondary-color;
}

.progress-points .dot.processing {
  background-color: #333;
  border-color: #333;
}

.progress-points .dot.skipped {
  background-color: $grey;
  border-color: $grey;
}

.progress-points .dash {
  display: inline-block;
  width: 36px;
  border-width: 1px;
  border-style: solid;
  background-color: $background-grey;
  border-color: $light-grey;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;
}

.progress-points .dash.done {
  background-color: $primary-color;
  border-color: $primary-color;
}

.progress-points .dash.skipped {
  background-color: $grey;
  border-color: $grey;
}
