@import "ri-global.scss";
.portal-accordion {
  display: flex;
  flex-flow: column;
  justify-content: stretch;

  height: 600px;
  overflow-y: hidden;

  .portal-accordion-item {
    padding: 10px;
    display: flex;

    transition: height 0.1s;

    .portal-accordion-item-header {
      width: 1.5em;
      font-size: 1.5em;
      font-weight: bold;
      color: $font-color;

      padding-right: 15px;

      cursor: pointer;

      text-align: center;

      writing-mode: vertical-rl;
      text-orientation: mixed;

      .glyphicon {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }

    .portal-accordion-item-body {
      font-size: 16px;

      height: 100%;
      width: 100%;

      text-align: left;

      background-color: $light-grey;

      textarea {
        height: 100%;
        width: 100%;
      }
    }
  }
}
