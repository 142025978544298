@import "ri-global.scss";
.description-table-actions {
  padding: 5px 5px;
  position: absolute;
  top: 0px;
  background: white;
  z-index: 10;
  width: 100%;
  height: 35px;
}

.description-table-actions.qc-edit {
  position: relative;
  margin-bottom: -35px;
}

.react-bs-table table td {
  white-space: normal;
  font-size: 12px;
}

.search-root .react-bs-table table {
  border: solid 1px #dddddd;
}

.search-root .react-bs-container-body {
  border-bottom: solid 1px #dddddd;
}

.react-bs-table table th {
  font-size: 12px;
}

.description-table-container {
  min-height: 30em;
  margin-top: 35px;
}

// set max with of suggestion menu items
.description-table-container .search_search .dropdown-item > span {
  display: inline-block;
  max-width: 450px;
  width: max-content;
  white-space: normal;
}

.description-table-container.loading .react-bs-container-body {
  opacity: 0.7;
}

.description-table-actions.panel {
  border-bottom: '1px solid #ddd';
  padding-bottom: 10px;
  margin-bottom: 40px;
}

.brand-table-actions {
  padding-right: 10px;
}

.description-table-container input[type='checkbox'] {
  height: 19px;
  width: 19px;
  padding: 0px 0px 0px 0px !important;
  margin: 0px 0px 0px 0px !important;
  cursor: pointer;
}

.description-table-container .react-bs-table-sizePerPage-dropdown .dropdown-menu {
  bottom: 100%;
  top: auto;
}

.table-condensed > tbody > tr > td.in-edit {
  position: absolute;
  overflow: visible;
}

.cell-staged-value {
  width: 100%;
  position: relative;
  font-weight: 600;
  font-size: 12px;
  background: $dark-grey;
  border: 1px solid $dark-grey;
  color: white;
  border-radius: 4px;
  padding: 2px 18px 2px 4px;
  word-break: break-word;
}

.in-staging,
.tracked {
  color: #008eaa;
}

.in-dict {
  color: $red-color;
}

.not-in-dict {
  color: $dark-grey;
}

.not-editable {
  cursor: not-allowed;
  font-style: italic;
  opacity: 0.9;
}

button.cell-clear-button {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 12px;
  height: 20px;
  padding: 2px 4px;
  line-height: 1;
  color: $grey;
}

.cell-staged-value button.cell-clear-button:hover {
  color: $background-grey;
}

.editable-desc-value .progress {
  height: 5px;
  margin-bottom: 0px;
}

.image-search-iframe .popover-content {
  background: white !important;
}

.image-search-iframe {
  opacity: 1 !important;
  max-width: 600px;
  z-index: 10000;
}

.image-search-iframe.bing-search {
  border: none;
}

.image-search-iframe .popover-content {
  max-width: 600px;
  opacity: 1;
  padding: 0px 0px 0px 0px;
  box-shadow: 0px 0px 5px rgba(black, 0.3);
}

.description-table-label-actions {
  float: right;
}

.description-table-label-actions .action-icon {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  color: $grey;
  visibility: visible;
  float: right;
}

.description-table-label-actions:hover .action-icon {
  visibility: visible;
}

.image-search-iframe .popover.right > .arrow:after {
  border-right-color: #919191 !important;
}

.description-table-label-actions .action-icon:active,
.description-table-label-actions .action-icon:hover {
  color: $font-color;
}

.in-edit .search_search input.rbt-input-hint,
.in-edit .search_search input[type='text'].rbt-input-main {
  font-size: 13px;
  height: 17px;
}

.in-edit .search_search {
  display: inline-block;
}

.in-edit .bootstrap-typeahead-input {
  padding-right: 20px;
}

.in-edit > div {
  padding: 2px 4px;
}

.table-chart-toolbar .dropdown-menu {
  min-width: auto;
}

.description-table-actions .description-toolbar-button {
  height: 25px;
}
.description-table-actions .description-toolbar-container {
  height: 25px;
  display: flex;
  flex-direction: row;
}
.description-table-actions .search_search .rbt-input {
  height: 25px;
  font-size: 13px;
  background: white;
  padding: 5px;
}

.description-table-actions .search_search .rbt-input input {
  font-size: 13px;
  padding: 5px;
}

.edited-by-user {
  font-size: 12px;
}

.drop-menu-up {
  .react-bs-table-pagination {
    :first-child {
      display: flex !important;
    }
  }
  .dropdown-menu {
    bottom: 100%;
    top: auto;
  }
  .btn .caret {
    width: 9px;
    height: 8px;
    margin-left: 8px;
    transform: rotate(180deg);
  }
}
