@import "ri-global.scss";
.portal-query-builder {
  button.qb-fields {
    width: 150px;
  }
  button.qb-operators {
    width: 125px;
  }

  .qb-add-rule,
  .qb-add-group {
    border: 0px;
    button {
      padding: 0px 0px;
    }
  }

  .qb-add-rule {
    margin-left: 26px;
    &::before {
      left: -20px;
    }
  }

  .qb-rule-group .ruleGroup-minimize {
    transition: transform 0.3s ease-in-out;
    &.closed {
      transform: rotateZ(-180deg);
    }
  }

  .qb-add-rule,
  .qb-add-group,
  .description-value-editor {
    .btn-link {
      &,
      &:hover,
      &:focus {
        color: $primary-color;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
      }
    }
  }

  .category-filter-comp {
    .rbt-input-wrapper {
      max-height: 100px;
      padding: 6px 0px;
      overflow-y: auto;
      position: relative;
    }

    .chips-search-box {
      margin-bottom: 10px;
    }

    .rbt-token {
      padding: 0px 18px 0px 6px;
      margin-bottom: 3px;
      background: $dark-grey;
      color: white;
      border: 1px solid $dark-grey;

      &.rbt-token-active {
        color: $dark-grey;
        background-color: transparent;
        border: 1px solid $dark-grey;
      }

      .rbt-token-remove-button:hover {
        color: white;
      }

      &,
      &.rbt-token-active {
        .rbt-token-remove-button {
          &,
          &:hover {
            color: $grey;
          }
        }
      }
    }
  }
  .btn-link {
    color: $grey;
    &:hover,
    &:focus {
      color: $font-color;
      text-decoration: none;
      font-weight: bold;
    }
  }
}
