@import "ri-global.scss";
.category-tests-page {
    .disabled-glyphicon {
      color: $light-grey;
    }
  }

.cme-dictionary-table-header {
  font-weight: bold !important;
  font-size: 14px;
}

.cme-dictionary-page {
  flex: 1;
  margin: 12px;
  overflow-y: auto;
}

.cme-dictionary-toolbar {
  display: flex;
  width: auto;
  align-items: center;
  justify-content: space-between;
  margin: 12px 12px 12px 0;
}

.cme-dictionary-toolbar-buttons {
  display: flex;
}