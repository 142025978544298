@import "ri-global.scss";
.rbt-input-multi .rbt-input-wrapper {
  margin-top: auto;
  margin-bottom: auto;
}

.rbt-token {
  position: relative;
  word-break: break-word;
  display: inline-block;
  line-height: 2;
  padding: 0px 18px 0px 1px;
  margin-bottom: 0px;
  font-size: 14px;
  border-radius: 3px;
  background: transparent;
  color: $dark-grey;
  border: 1px solid transparent;
}

.rbt-token.rbt-token-active {
  border: 1px solid $primary-color;
}

.remove-all-values-wrapper {
  height: 18px;
}

.remove-all-values {
  color: $primary-color;
  font-weight: bold;
  cursor: pointer;
  font-size: 12px;
  line-height: inherit;
  float: right;
}

.dropdown-menu.rbt-menu {
  border: 1px solid $primary-color;
  border-radius: 0px;
  margin-top: -1px;
  min-width: 220px;
}

.rbt-token.rbt-token-active .rbt-token-remove-button,
.rbt-token .rbt-token-remove-button:hover {
  color: $primary-color;
}

.rbt-token .rbt-token-remove-button {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 18px;
  height: 20px;
  padding: 4px 4px;
  line-height: 1;
  color: $grey;
  z-index: 10;
}

.chips-selector .search_search input[type='text'].rbt-input-main {
  font-size: inherit;
  margin-top: 6px;
}

.chips-search-box .dropdown-menu .subtext {
  font-size: 12px;
  color: #999999;
}

.chips-search-box .dropdown-menu .active mark,
.chips-search-box .dropdown-menu .active .subtext {
  color: white;
}
