@import "ri-global.scss";
.header.alignItemsCenter.flex .dropDownButton.dropDownButton--faq {
  margin-right: 30px;
}

.header.alignItemsCenter.flex .dropDownButton.dropDownButton--faq svg {
  width: 24px;
  height: 24px;
  opacity: 0.7;
}

.header.alignItemsCenter.flex .dropDownButton.dropDownButton--faq:hover svg {
  opacity: 1;
}

.dropDownButton--faq.active.alignItemsCenter.flex #ic-help path {
  color: $primary-color;
  fill: $primary-color;
  opacity: 1;
}

.header.alignItemsCenter.flex .dropDownButton.dropDownButton--faq:hover #ic-help path {
  color: $primary-color;
  fill: $primary-color;
  opacity: 1;
}

.containerMenu.bottom.center.dropDownButton--faq {
  position: absolute;
  top: 58px;
  right: 63px;
  border: 1px solid $primary-color;
}

.containerMenu .bottom .center .dropDownButton--faq {
  right: 60px;
  top: 65px;
}
