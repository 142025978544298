@import "ri-global.scss";

.sb-form .number-input-box {
  margin-bottom: 3px;
  margin-top: 3px;
  position: relative;
}

.sb-form .number-input-box input.form-control {
  height: 30px;
  border: 1px solid $light-grey;
  margin: 0px 6px;
}

.sb-form .number-input-box+.dropdown {
  margin-left: 0px;
}

.sb-form .number-input-box input.form-control:hover {
  border-color: $dark-grey;
}

.sb-form .number-input-box input.form-control:focus {
  border-color: $primary-color;
}

.sb-form .number-input-box label.control-label {
  color: $dark-grey;
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
}

.sb-form .number-input-box.with-suffix .form-control {
  padding-right: 12px;
}

.sb-form .number-input-box .input-group-addon {
  display: inline-block;
  position: relative;
  right: 23px;
  font-size: 0.8em;
  color: $grey;
  width: 0px;
  padding: 0px;
}
