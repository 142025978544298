@import "ri-global.scss";
.report-folders-page {
  min-height: calc(100vh - 100px);
}

.report-folders-page .breadcrumb.folder-breadcrumb {
  margin-bottom: 0px;
  background-color: transparent;
}

.report-folders-page .folder-breadcrumb > li + li:before {
  content: '/';
}

.report-folders-page .folder-breadcrumb > li .glyphicon {
  margin-left: 6px;
  margin-right: 4px;
}

.report-folders-page .folder-breadcrumb > li .folder {
  display: inline-block;
}

.report-folders-page .folder.link {
  cursor: pointer;
  color: $primary-color;
}

.report-folders-page .folder.link:hover {
  text-decoration: underline;
}

.folder-details .sub-folder-header {
  height: 34px;
}

.sub-folder-header .folder-actions {
  float: right;
}

.folder-details ul.sub-folders,
.folder-details ul.reports {
  list-style: none;
  padding-left: 16px;
  clear: both;
}

.folder-details li.folder-item {
  line-height: 24px;
  margin-left: 12px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  display: inline-flex;
  width: 100px;
  text-align: center;
}

.folder-details.thumbnail-view li.folder-item {
  display: inline-block;
  max-width: 140px;
  text-align: center;
}

.folder-details li.folder-item a {
  color: $dark-grey;
  text-decoration: none;
  font-size: 16px;
}

.folder-details li.folder-item .draggable {
  display: inline-block;
  background: transparent;
}

.folder-details li.folder-item .glyphicon {
  margin: 0 auto;
  font-size: 48px;
  display: block;
  color: slategray;
}

.folder-details li.folder-item .folder-item-name,
.folder-details li.folder-item input[type="text"] {
  width: 100%;
  word-break: break-word;
  line-height: 1.1;
  text-align: center;
}

.folder-details li.folder-item input[type="text"]:focus {
  background-color: white;
}

.folder-details li.folder-item input[type="text"]:read-only:focus {
  background-color: transparent;
}

.folder-details.thumbnail-view li.folder-item .glyphicon {
  margin-right:6px;
  font-size: 48px;
  display: block;
}

.folder-details ul .no-items {
  color: #bbbbbb;
}

.folder-details {
  padding: 0 24px;
  position: relative;
  border: 2px dashed transparent;
  min-height: 400px;
}

.folder-details.is-receiving-a-drop {
  border-color: #bbbbbb;
}

.folder-details h2 {
  margin: 0px;
  padding: 0px;
  height: 34px;
}

.folder-details h2 .glyphicon {
  margin-right: 6px;
}
.folder-details h2 .folder-item-name {
  display: inline-block;
}

.is-dragged {
  opacity: 0.1;
}


.folder, .file {
  transition: color 300ms, background-color 200ms;
  padding: 3px 6px;
}

.sub-folders-details li.folder-item > div {
  width: 100%;
}

.file:focus,
.folder:focus {
  background-color: #dddddd;
}

.is-receiving-a-drop > .folder {
  color: $primary-color !important;
  background-color: #dddddd;
}

.drop-message {
  text-align: center;
  font-size: 60px;
  color: #bbbbbb;
  position: absolute;
  display: none;
  top: 30px;
  left: 30px;
  bottom: 30px;
  right: 30px;
  z-index: -1;
  overflow: hidden;
}

.is-receiving-a-drop .drop-message {
  display: block;
}
