@import "ri-global.scss";
/* Sidebar Styles */

$menu-width: 245px;
$menu-font-size: 16px;

.sidebar-nav .sidebarSelectedPanel {
  color: white;
}

.sidebar-nav {
  list-style: none;
  height: calc(100vh - 59px);
  width: 50px;
  float: left;
  background-color: #4c4c4c;
  position: fixed;
  overflow: hidden;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 0%;
  margin-top: 59px;
  z-index: 9999;
  transition: width 0.5s ease-out;
}

// close menu Item with empty submenu
.root-nav-item:has(.sub-menu-dropdown:empty) {
  display: none;
}

.willBeInvisible {
  visibility: hidden;
  opacity: 0;
  vertical-align: sub;
}

// Open state
.sidebar-nav-open {
  width: $menu-width;
  overflow-y: auto;
}

.sidebar-nav-open .willBeInvisible {
  visibility: visible;
  opacity: 1;
}

.rotationStop {
  transform: rotate(0deg);
  transition: 400ms transform;
}

.rotationStart {
  animation: spin 400ms;
  transform: rotate(90deg);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(90deg);
  }
}

/* Hover effect on left-nav bar. Collapsible. END*/
.sidebar-nav li {
  line-height: 14px;
  position: relative;
}

.sidebar-nav .collapse,
.sidebar-nav:hover .collapse {
  position: relative;
}

.sidebar-nav:hover .collapse.in {
  position: relative;
}

// highlight menu item
.sidebar-nav a.sub-menu-link.active,
.sidebar-nav a.sub-menu-link:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(black, 0.4);
}

// Reports Menu
.sidebar-nav .sideBarReport a {
  font-size: 14px;
}
.sidebar-nav .sideBarReport a.active,
.sidebar-nav .sideBarReport a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba($primary-color, 0.4);
}

.sidebar-nav .reports-search-box {
  padding: 9px 18px 9px 46px;
}
.sidebar-nav .reports-search-box .input-search {
  color: white;
  width: 166px;
}
.sidebar-nav .reports-search-box .input-search svg,
.sidebar-nav .reports-search-box input[type='text'] {
  color: white;
}

.sidebar-nav .reports-search-box input[type='text'] {
  border-bottom: 1px solid #999999;
  width: 166px;
}
.sidebar-nav .reports-search-box input[type='text']:focus {
  border-bottom: 1px solid #ffffff;
}
// -end- Reports

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #999999;
  word-break: break-word;
  width: $menu-width;

  font-size: $menu-font-size;

  padding: 10px 18px 10px 46px;
}

.sidebar-nav li a.sidebar-nav-link {
  padding-left: 0px !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

.sidebar-nav li a.active,
.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
  color: #fff;
}

.sidebar-nav li.root-nav-item {
  font-size: $menu-font-size;
  width: $menu-width;
  color: #999999;
  margin-bottom: 5px;
}

.sidebar-nav .root-nav-item-main {
  cursor: pointer;
  padding-top: 13px;
  padding-bottom: 5px;
}

.sidebar-nav:hover li.root-nav-item:hover,
.sidebar-nav:hover li.root-nav-item:hover a:hover {
  color: #ffffff;
}

.sidebar-nav .root-nav-item .spanRight {
  float: right;
  margin-right: 18px;
}

.sidebar-nav .root-nav-item-label {
  font-weight: bold;
}

.sidebar-nav .root-nav-item-icon {
  margin-left: 10px;
  padding-right: 6px;
  vertical-align: middle;
}
