@import "ri-global.scss";
.CategoriesInput__container {
  margin-top: 20px;
  display: flex;
}

.CategoriesInput__container .input {
  width: 70%;
}

.CategoriesInput__container .medium {
  display: flex;
  flex: 1;
}

.CategoriesInput__container .medium {
  flex: 1;
}

.CategoriesInput__container span.medium {
  cursor: pointer;
}

.ItemAttributesInput__container div.typeContainer > div > div.flex.items.wrap label {
  overflow: visible;
}

.CategoriesInput__container .directionColumn {
  flex-direction: row;
  flex: 1;
}

.CategoriesInput__container .actions {
  position: initial;
  top: 0;
}

.CategoriesInput__container .actions a {
  margin-left: 20px;
}

.CategoriesInput__container > label {
  white-space: nowrap;
}

.CategoriesInput__container .items {
  overflow: initial;
  margin-top: 20px;
  margin-left: 0px;
  overflow: scroll;
}

.CategoriesInput__container .flex1 {
  flex: 2;
}

.CategoriesInput__container .CheckBoxMultiSelect.container {
  margin-top: 0;
}

.CategoriesInput__container .CheckBoxMultiSelect .typeContainer {
  background-color: white;
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 5px;
  margin-top: 10px;
}

.MerchantsInput__container input.bootstrap-typeahead-input-main {
  padding: 6px 10px;
  border-radius: 5px;
  background-color: white !important;
  font-size: 14px;
  border: 2px solid #ddd;
  width: 200px;
  border-width: 1px;
}

.MerchantsInput__container {
  margin-bottom: 20px;
}

.MerchantsInput__container div.container {
  background-color: white;
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 5px;
  margin-top: 10px;
}

.MerchantsInput__container .merchant-names {
  background-color: #f5f5f5;
  text-align: left;
  padding: 15px;
  border: solid 1px #ddd;
  border-radius: 3px;
}

.MerchantsInput__container .merchant-names .title {
  margin-bottom: 10px;
  display: inline-block;
}

.MerchantsInput__container .merchant-names ul {
  list-style: none;
}

.MerchantsInput__container .remove-merchant {
  position: relative;
  left: 2px;
  top: -1px;
}

.MerchantsInput__container .error {
  padding: 10px;
  border: solid 1px #f3dcdc;
  background-color: #fdefef;
  display: inline-block;
  border-radius: 5px;
  margin-top: 10px;
}

.clear-action {
  margin-top: 10px;
  display: inline-block;
}

.MerchantsInput__container .hidden {
  display: none;
}

.MerchantsInput__container .visibilityStyle {
  color: $primary-color;
  font-weight: bold;
  margin-bottom: 10px;
  cursor: pointer;
}
