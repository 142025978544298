@import "ri-global.scss";
.TextareaModal__textarea {
  color: black;
  resize: none;
  margin: 10px 0 30px 0;
  outline: none;
  border: 1px solid $primary-color;
}

.TextareaModal__header {
  font-size: 14px;
}

div.fade.in.error-modal.textarea-modal.modal >
  div > div > div.modal-body {
  margin-bottom: 0;
}

div.fade.in.error-modal.textarea-modal.modal > div {
  width: 500px;
}
