@import "ri-global.scss";
.search-root {
  min-width: 700px;
  margin: 0px;
  margin-top: 3px;
  width: 100%;
  padding: 15px;
}

.field-dropdown {
  font-size: 14px;
}

.search-input {
  width: 100%;
  height: 38px;
}

.btn-danger {
  background-color: #e51c23;
}

.btn-success {
  background-color: #4caf50;
}

.search-form {
  font-size: 13px;
  width: 100%;
}

.btn_query {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  border: none;
  margin-right: 8px;
  text-transform: uppercase;
}

.not-btn,
.btn-complete {
  color: black !important;
}

.activeTermsToolbar {
  width: 100%;
}

/* Override for the select dropdown to show with a higher z index as by default bootstrap sets input groups to z-index of 2 */
.input-group .form-control {
  z-index: 0;
}

.search-form input {
  background-color: transparent;
  outline: none;
  border: none !important;
  font-weight: normal;
}

.not {
  color: red !important;
}

.delete-badge {
  background: red;
}

#query-tree a {
  color: black;
}

.col-no-padding-left {
  padding-left: 0;
}

.col-no-padding-right {
  padding-right: 0;
}

.qb-rule .description-value-editor {
  width: 100%;
}

.qb-rule textarea {
  width: 100%;
  outline: none;
  min-height: 22px;
  resize: none;
  padding: 4px 0px 2px 0px;
  color: black;
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-box-shadow: inset 0 -1px 0 #ddd;
  box-shadow: inset 0 -1px 0 #ddd;
  font-size: 13px;
}

.qb-rule textarea::placeholder {
  color: #aaaaaa;
}

.search-root .query-builder .search_search .rbt-input {
  border: none;
  box-shadow: none;
  border-bottom: 2px solid #cccccc;
  border-radius: 0;
  padding: 0px 0px;
}

.search-root .query-builder .search_search .rbt-input.focus {
  border-color: $primary-color;
}

.search_search .query-builder input.rbt-input-main + div {
  width: 100% !important;
}

.search_search .query-builder input.rbt-input-hint,
.search_search .query-builder input[type='text'].rbt-input-main {
  padding: 0;
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-box-shadow: inset 0 -1px 0 #ddd;
  box-shadow: inset 0 -1px 0 #ddd;
  font-size: 13px;
  width: 100% !important;
}

.sq_typeahead_name {
  display: inline-block;
  width: 100%;
}
.sq_typeahead_name.selected ::-webkit-input-placeholder {
  color: $primary-color;
}
.group_name_edit {
  display: inline-block;
  width: 93%;
  font-size: 16px;
}
.cancel_edit_mode {
  display: inline-block;
  background-color: white;
  font-size: 15px;
  margin-left: -18px;
}
.query_name_edit {
  display: inline-block;
  width: 100%;
  height: 30px;
  font-size: 16px;
}
.sq_name_edit {
  border: 0px !important;
  border-radius: 0px !important;
  height: 30px;
  box-shadow: inset 0 1px 1px rgba(black, 0.075);
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: white;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(black, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.create_Typeahead_create {
  font-weight: bold;
}
.create_Typeahead_Wrapper_icon {
  display: inline-block;
  margin-left: -18px;
  font-size: 16px;
  color: lightgray;
  z-index: 1;
  background-color: inherit;
  position: relative;
}
.group_search_icon,
.group_delete_icon,
.query_search_icon,
.query_delete_icon {
  display: inline-block;
  font-size: 18px;
  color: $secondary-color !important;
  padding: 0px 0px;
}
.group_search_icon,
.query_search_icon {
  padding-right: 10px;
  color: $primary-color !important;
}
.sq_edit_comp {
  height: 30px;
}
.sq_search_comp {
  display: inline-block;
  width: 100%;
}
.query_arrow {
  margin-left: 5px;
  display: inline-block;
  font-size: 16px;
  padding: 0px;
  vertical-align: top;
}
.query_arrow.selected,
.query_arrow.selected .btn-link:hover,
.query_arrow.selected .btn-link:focus {
  color: $primary-color !important;
}
.sq_query_action,
.sq_group_action {
  margin-right: 5px;
}
.sq_query_action {
  padding-top: 2px;
}
.sq_group_comp {
  height: 30px;
  width: 100%;
}
.sq_group_value {
  display: inline-block;
  width: 70%;
}
.sq_query_comp {
  padding-top: 10px;
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
}
.sq_query_value {
  display: inline-block;
  width: 70%;
  height: 30px;
}
.sq_action {
  margin-top: 5px;
  height: 30px;
}
.sq_active_icon {
  text-transform: uppercase;
  color: $primary-color;
}
.create_Typeahead_create .sq_active_icon::after {
  content: ': ';
}

.active.dropdown-item .create_Typeahead_create .sq_active_icon {
  color: inherit;
}

.tree-node-edit-form input[type='text'] {
  height: 19px;
}

.search-filters {
  font-size: 16px;
}

.search-filters .panel-group {
  margin-bottom: 10px;
}
.search-filter-wrapper {
  margin-bottom: -15px;
}
.qc-description-table-container .react-bs-container-body {
  height: auto;
}

.btn-toolbar .btn,
.btn-toolbar .btn-group,
.btn-toolbar .input-group {
  margin-left: 5px;
  outline: none !important;
}

.show-tree-btn span {
  display: inline;
}

.show-tree-btn.closed > span {
  margin-left: -20px;
  transition: margin-left 0.3s 0.3s ease-in-out;
  -webkit-transition: margin-left 0.3s 0.3s ease-in-out;
}

.show-tree-btn.open > span {
  margin-left: 5px;
  transition: margin-left 0.3s 0.3s ease-in-out;
  -webkit-transition: margin-left 0.3s 0.3s ease-in-out;
}

.show-tree-btn .text {
  padding: 0px 5px;
  font-weight: bold;
}

.show-tree-btn,
.show-tree-btn:hover,
.show-tree-btn:visited,
.show-tree-btn:focus {
  width: 70px;
  right: -70px;
  overflow: hidden;
  padding: 10px 5px;
  font-size: 12px;
  cursor: pointer;
  top: 0px;
  height: 35px;
  border: none;
  position: absolute;
  border-radius: 0px 0px 4px 0px;
  box-shadow: 8px 0px 16px rgba(45, 45, 45, 0.1);
  background-color: white !important;
}

.rdr-Days span {
  float: left;
}

.query-builder-wrapper {
  position: fixed;
  height: calc(100% - 60px);
  z-index: 100;
  background: white;
  margin-top: -18px;
  margin-bottom: -18px;
  padding: 10px;
  width: 400px;
  box-shadow: 8px 0px 16px rgba(45, 45, 45, 0.1);
  transition: all 0.5s ease;
  // max-width: 800px;
}

.query-builder-wrapper.active {
  margin-left: -15px;
  min-width: 630px;
  width: var(--size);
}

.query-builder-wrapper.active .query-builder {
  opacity: 1;
}

.query-builder-wrapper.inactive {
  margin-left: -405px;
}

.query-builder-wrapper.inactive .query-builder {
  opacity: 0;
}

.query-builder {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.queryBuilder {
  flex-grow: 2;

  overflow-y: auto;
}

.query-apply-container {
  padding: 10px 5px 0 5px;
  border-top: 1px solid $light-grey;
}

.query-tree {
  overflow-y: auto;
  height: calc(100% - 110px);
  margin-top: 10px;
}

span.tree-node.tree-node-attribute {
  display: inline-block;
  width: 300px;
  vertical-align: top;
}
