@import "ri-global.scss";
.data-summary-widget {
  padding: 10px;
  display: inline-block;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.data-summary-widget.small {
  padding: 5px 10px;
}
.data-summary-widget .table {
  margin-bottom: 0px;
}

.data-summary-widget table th {
  font-size: 14px;
  margin: 10px;
  padding: 5px 5px !important;
}

.data-summary-widget table td {
  font-size: 14px;
  padding: 5px 5px !important;
}

.data-summary-widget.small table th {
  font-size: 12px;
  margin: 10px;
  padding: 3px 3px !important;
}

.data-summary-widget.small table td {
  font-size: 12px;
  padding: 3px 3px !important;
}

.data-summary-header {
  display: block;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
}
.data-summary-header .title {
  margin: 10px 0px;
  display: inline-block;
}

.data-summary-header select.form-control {
  padding: 0px 0px 0px 0px;
  height: 30px;
  border: none;
  box-shadow: none;
}
