@import "ri-global.scss";
.step-panel .delivery-sub-step-wrapper {
  position: relative;
}

.step-panel .sub-step {
  line-height: 18px;
  font-size: 14px;
  margin: 6px 0px 6px 15px;
  display: inline-block;
}

.step-panel .sub-step.pending .label {
  color: $dark-grey;
  opacity: 0.6;
}

.step-panel .sub-step.processing .label,
.step-panel .sub-step.completed .label {
  color: $dark-grey;
}

.step-panel .sub-step.failed .label {
  color: $secondary-color;
}

.step-panel .collapse.in .sub-step::before {
  content: '';
  position: absolute;
  height: 11px;
  width: 11px;
  background-color: white;
  border: 1px solid $font-color;
  left: -20px;
  top: 10px;
}

.step-panel .collapse.in .sub-step.pending::before {
  background-color: white;
  border-color: $font-color;
}

.step-panel .collapse.in .sub-step.processing::before {
  background-color: $dark-grey;
  border-color: $dark-grey;
}

.step-panel .collapse.in .sub-step.completed::before {
  background-color: $primary-color;
  border-color: $primary-color;
}

.step-panel .collapse.in .sub-step.failed::before {
  background-color: $secondary-color;
  border-color: $secondary-color;
}

.step-panel .step-actions {
  margin-left: 6px;
  display: inline-block;
}

.step-panel .step-actions .step-action {
  padding-left: 6px;
  display: inline-block;
  margin: 0px 3px;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  color: $primary-color;
  vertical-align: middle;
}

.step-panel .step-actions .step-action:hover {
  text-shadow: 0px 2px rgba(222, 222, 222, 0.7);
}

.step-panel .step-actions .step-action:active {
  color: $dark-primary-color;
}

.step-panel .sub-step + .details-box {
  margin: 6px 6px 12px 15px;
  border: 1px solid $font-color;
  padding: 12px;
}

.step-panel .sub-step.failed + .details-box {
  border-color: $secondary-color;
  white-space: pre;
}
