@import "ri-global.scss";
.flex {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.noWrap {
  flex-wrap: nowrap;
}

.directionColumn {
  flex-direction: column;
}

.directionRow {
  flex-direction: row;
}

.directionRow .medium {
  padding-right: 20px;
  text-decoration: none;
  cursor: pointer;
}

.row {
  flex-direction: row;
}

.alignItemsCenter {
  align-items: center;
}

.alignItemsBaseLine {
  align-items: baseline;
}

.alignItemsEnd {
  align-items: flex-end;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyContentStart {
  justify-content: flex-start;
}
.justifyContentAround {
  justify-content: space-around;
}

.justifyContentEnd {
  justify-content: flex-end;
}

.auto {
  flex: auto;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flex3 {
  flex: 3;
}

.flex4 {
  flex: 4;
}
