@import "ri-global.scss";
.feed-module-page {
  width: 80%;
  margin: 0px auto;

  .feed-module-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
  }

  .feed-module-query-alert-icon {
    margin-right: 10px;
  }

  .feed-module-form {
    .Toggle {
      display: inline-block;
      vertical-align: top;
      margin-right: 30px;

      &.inactive {
        padding-left: 55px; /* for "Inactive" text */
      }
    }
  }

  .feed-module-select-container {
    display: flex;

    .feed-module-select {
      width: 600px;
    }

    .feed-module-select-btn {
      height: 36px;
      margin-left: 10px;
    }
  }

  .feed-module-help-title {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .feed-module-help-title-editor-label {
      font-size: small;
      strong {
        color: $grey;
      }
      span {
        margin: 0 5px;
      }
    }
  }

  .feed-module-query-form {
    margin: 10px 0 45px 0;
  }
}

#feed-modules-sql-binding-paramenters-help {
  max-width: 400px;
}
