@import "ri-global.scss";
.taxonomy-page {
  flex: 1;
  margin: 12px;
  overflow-y: auto;
}

.taxonomy-page h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 12px;
  padding-left: 6px;
}

.taxonomy-edit-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.taxonomy-edit-modal input {
  width: 200px;
}

.merchant-fields-modal {
  height: 525px;
  overflow: auto;
}

.merchant-fields-modal-total {
  height: 18px;
  text-align: left;
  margin-top: 10px;
}
