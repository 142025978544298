@import "ri-global.scss";
.dropdown-wrapper {
  background-color: white;
  font-size: 14px;
  border: 1px solid $light-grey;
  border-bottom: none;
  color: $dark-grey;
  width: 300px;
}

.dropdown-wrapper .dropdown-button {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid $light-grey;
}

.dropdown-wrapper .dropdown-button:hover {
  border-bottom: 0.5px solid $grey;
}

.dropdown-wrapper .dropdown-button .caret-wrapper{
  float: right;
}

.dropdown-wrapper .dropdown-options{
  position: absolute;
  list-style-type: none;
  z-index: 999;
  background-color: white;
  padding: 0px;
  margin-left: -1px;
  width: 300px;
  border: 1px solid $primary-color;
  border-top: none;
}

.dropdown-wrapper .dropdown-options .dropdown-option{
  cursor: pointer;
  text-align: left;
  padding: 10px;
  padding-left: 20px;
}

.dropdown-wrapper .dropdown-options .dropdown-option.active {
  font-weight: bold;
}

.dropdown-wrapper .dropdown-options .dropdown-option:hover{
  background-color: #f5f5f5;
}

.spanRightArrowIconLeaderboard {
  position: fixed;
  margin-left: 16.5em;
  margin-top: -0.7em;
}

.borderSliceTeal {
  border: 1px solid $primary-color;
}

.borderNormal:hover {
  cursor: pointer;
  border: 0.5px solid $grey;
  border-bottom: none;
}

.borderBottomOpen {
  text-align: center;
  border-bottom: 1px solid !important;
  border-image: linear-gradient(to right, white 8%, $light-grey 8%, $light-grey 92%, white 92%, white 100%) 5;
}
