@import "ri-global.scss";
.rules-page {
  flex: 1;
  margin: 12px;
  overflow-y: auto;
}

.rules-page h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 12px;
  padding-left: 6px;
}

.rules-deploy {
  font-size: 14px;
  .glyphicon {
    padding-right: 8px;
  }

  .deploy-refresh{
    margin-top:60px;
  }

}
