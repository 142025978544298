@import "ri-global.scss";
/* tabs, tabs wrapper, tab content starts */
#dm-tabs-wrapper {
  margin-bottom: -70px;
}
#dm-tabs-wrapper .tab-content {
  background-color: white;
  min-height: 80vh;
  padding-top: 30px;
  padding-bottom: 200px;
  box-shadow: 0 -2px 24px 0 rgba($dark-grey, 0.1);
}

#dm-tabs-wrapper .nav-tabs {
  border-bottom: none;
}

#dm-tabs-wrapper .react-bs-table-container {
  margin-top: 24px;
}

#dm-tabs-wrapper .react-bs-table-container + .pagination-wrapper {
  margin: 18px auto;
  text-align: center;
}

.badge {
  border-radius: 20px;
  background-color: #bebebe;
  font-size: 10px;
  line-height: 12px;
}

.tab:hover .badge,
.tab.active .badge {
  background-color: $grey;
}

.feed-deliveries-page {
  margin: 1% 2%;
}

.feed-deliveries-page .btn-toolbar {
  padding: 12px;
}

.data-feed-tabs {
  padding-left: 20px;
}

.feed-group-locks-table {
  margin: 1% 2%;
}

.feed-group-locks-table-column-title {
  text-transform: capitalize;
}

.client-feed-list {
  font-size: large;
}

.config-dropdown {
  padding: 20px;
}

.config-dropdown-container {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.exclusion-buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.exclusion-table-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.exclusion-table {
  width: auto;
  margin: 20px;
}

.date-range-filter {
  margin-left: 10px;
}

.feed-deliveries-toolbar {
  padding-right: unset !important;
  margin-top: 20px;
  margin-bottom: 10px;
}

.feed-deliveries-summary-page {
  padding-left: 20px;
  padding-right: 20px;
  display: inline-flex;
  flex-direction: column;
}

.feed-deliveries-summary-toolbar {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feed-deliveries-summary__group-search-input {
  display: flex;
  align-items: center;
  width: 40%;
  margin-left: 10px;
}

.feed-deliveries-summary__group-search-input__label {
  background-color: inherit;
}

.feed-deliveries-summary__group-search-input__input-box {
  width: 80%;
  margin-left: 10px;
}

.feed-deliveries-summary-page__help-icon {
  margin-left: 10px;
}

.feed-deliveries-summary___group-filters___checkboxes {
  margin-left: 10px;
}

.feed-deliveries-summary___group-filters {
  flex-grow: 2;
}

.checkbox input[type='checkbox'],
.checkbox-inline input[type='checkbox'],
.radio input[type='radio'],
.radio-inline input[type='radio'] {
  margin-top: -1px;
  margin-left: -23px;
}
