@import "ri-global.scss";
.query-header {
  min-height: 110px;
  padding: 5px;
  width: 100%;
  .qb-selected-group,
  .qb-selected-query {
    position: relative;
    word-break: break-word;
    display: inline-block;
    padding: 0px 0px;
    font-size: 16px;
    line-height: 1.6;
    border-radius: 3px;
    color: black;
  }

  .qb-selected-query {
    vertical-align: top;
  }
  .qb-group-name,
  .qb-query-name {
    color: gray;
    font-size: 13px;
  }
  .qb-query-name {
    vertical-align: top;
    padding-bottom: 3px;
    display: inline-block;
  }

  .btn-link {
    color: $grey;
    &:hover,
    &:focus {
      color: $font-color;
      text-decoration: none;
      font-weight: bold;
    }
  }
}

.search-filters-container {
  padding-bottom: 0.5em;

  > .btn-group {
    width: 100%;
    > .btn {
      width: 100%;
      text-align: left;
      > .caret {
        float: right;
        margin-top: 4%;
      }
    }
  }

  ul.dropdown-menu {
    width: 100%;
  }
}

.search-filter {
  margin: 0px 5px 0px 5px;
  > .filter {
    margin-bottom: 2px;
    height: 20px;
    > .label {
      font-size: 14px;
      font-weight: normal;
    }
  }
}

.search-filter-label {
  font-size: 14px;
  font-weight: normal;
}

.label-capitalize {
  text-transform: capitalize;
}

.add-to-dict-form-control {
  border: solid !important;
  border-width: thin !important;
}

.pagination > li {
  display: table-cell;
}

.result-table-toolbar {
  padding: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 5px;
  color: #fff;
  background: $grey;
  font-size: larger;
}

.refresh-button {
  margin-left: 10px;
}

.whitespace-normal {
  white-space: normal !important;
}

// z-index of our modals is 10001
// so setting this to 10002 so
// it shows above the modal
.tooltip {
  z-index: 10002 !important;
}

.text-primary {
  color: $primary-color;
}

.sampling-qc-date-range {
  display: inline-block;
  padding-right: 10px;

  .sampling-qc-date-range-filter {
    padding-left: 5px;
  }
}

.mt-2 {
  margin-top: 0.5rem;
}

.m-2 {
  margin: 0.5rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.custom-dialog {
  width: 1500px;
}

.scrollable-modal-body {
  overflow-y: auto;
  height: 70vh;
}

.regex-utility-modal {
  width: 90%;
  max-width: none !important;
}

.popover {
  z-index: 10002 !important;
}

.action-icon {
  color: $font-color;
  &:hover,
  &:focus {
    color: $font-color;
  }
}
