@import "ri-global.scss";
.category-delete-modal {
  font-size: 16px;

  .category-delete-modal-validation {
    padding: 15px 0 45px 0;

    text-align: left;

    .glyphicon {
      padding-right: 10px;
    }
  }

  .glyphicon-ok-sign {
    color: $green-color;
  }

  .glyphicon-minus-sign {
    color: $yellow-color;
  }
}
