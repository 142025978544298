@import "ri-global.scss";
.auth-event {
  font-weight: bold;
}

tr .auth-strategy,
tr .auth-event-desc,
tr .user-email,
tr .auth-event-initiator {
  font-size: 10px;
}

img.ip-flag {
  padding: 0px 5px 0px 0px;
  height: 12px;
  width: 20px;
  margin-top: -2px;
}

.ip-organisation {
  font-size: 9px;
}

.ip-threat {
  color: red;
  text-transform: uppercase;
  font-size: 16px;
}

.ip-address {
  margin-bottom: 5px;
}

.authlogs-table tr.success,
.authlogs-table tr.info,
.authlogs-table tr.danger,
.authlogs-table tr.warning,
.authlogs-table thead tr {
  border-left: 5px solid;
  border-radius: 3px;
}

.authlogs-table tr.danger {
  border-left-color: red;
}

.authlogs-table tr.success {
  border-left-color: green;
}

.authlogs-table tr.info {
  border-left-color: #004c82;
}

.authlogs-table tr.warning {
  border-left-color: orange;
}

.authlogs-table thead tr {
  border-left-color: $dark-grey;
}

.authlogs-table .table>tbody>tr.success>td,
.authlogs-table .table>tbody>tr.info>td {
  background-color: transparent;
}

.authlogs-table .table>tbody>tr.success:hover>td {
  background-color: #d0e9c6;
}

.authlogs-table .table>tbody>tr.info:hover>td {
  background-color: #c4e3f3;
}

.authlogs-table th {
  text-transform: capitalize;
}

.authlogs-page .btn-toolbar {
  padding-bottom: 24px;
}
