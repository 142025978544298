@import "ri-global.scss";
.JobList .ReactVirtualized__Table__row {
  vertical-align: middle;
  padding: 5px 5px;
  border-bottom: 1px solid #ddd;
}

.JobList groupLogo {
  width: 60px;
}

.JobList groupLogo  > img {
  max-width: 50px;
  max-height: 50px;
  text-align: center;
  border-radius: 5px;
  border: 2px solid #ddd;
  border-radius: 5px;
}

.JobList tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.JobList .tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

.JobList .tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.JobList .tooltip:hover .tooltiptext {
  visibility: visible;
}

.JobList .label {
  padding: 4px 6px 4px 6px;
  color: white;
  display: inline-block;
  cursor: pointer;
}

.JobList .metadataPeek {
  position: absolute;
  right: 1px;
  top: 3px;
  padding: 2px 5px;
  transition: transform 0.2s ease-out;
}

.JobList .metadataPeek:hover {
  transform: scale(1.3);
}

.JobList .ReactVirtualized__Table__headerColumn {
  text-align: center;
}

.JobList .ReactVirtualized__Table__rowColumn {
  position: relative;
  text-align: center;
  white-space: normal;
}

.job-cell-metadata.ReactVirtualized__Table__rowColumn {
  text-align: left;
  white-space: nowrap;
}

.job-orchestrate .form-horizontal .form-group {
  margin-left: 0px;
  margin-right: 0px;
}

.job-orchestrate .form-horizontal .control-label {
  margin-bottom: 5px;
  margin-right: 5px;
  text-transform: uppercase;
}

.job-orchestrate.panel {
  border-color: $grey;
}

.job-orchestrate.panel>.panel-heading {
  background-color: $grey;
  border-color: $grey;
}

.job-orchestrate.panel>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: $grey;
}

.job-orchestrate.panel>.panel-heading a {
  color: white;
}

#jobFormMetadata {
  width: auto;
}

/* Compensate for app header height - use for all modals */
.job-modal {
  padding-top: 100px;
}

.job-modal .modal-header {
  display: block;
  text-align: left;
}

.job-modal .modal-content {
  color: $font-color;
  text-align: left;
}

.job-metadata.modal pre.metadata-viewer {
  white-space: pre-wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}

.job-cell {
  height: 100%;
  line-height: 16px;
}

.job-cell table td {
  padding: 3px;
}

.job-duration,
.job-created-date,
.job-updated-time-ago {
  font-size: 10px;
}

.job-metadata-wrapper>div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.job-orchestrate.panel .dropdown-menu {
  width: 70vw;
  max-height: 80vh;
  overflow-y: auto;
}
.job-orchestrate.panel li[role="presentation"] {
  display: inline-block;
  margin: 3px 5px;
}

.job-orchestrate.panel .dropdown-menu>li>a {
  padding: 3px 5px;
  border: 0px solid;
  border-radius: 3px;
  background-color: #ddd;
  transition: all 0.2s ease-in-out;
}

.job-orchestrate.panel .dropdown-menu>li>a:hover {
  background-color: $primary-color;
  color: white;
  font-weight: bold;
}

.job-orchestrate.panel .checkbox-inline {
  line-height: 27px;
  margin-left: 10px;
  font-size: 16px;
}

#jobs-page-tabs .tab-content {
  padding-top: 10px;
}

.etl-states-table td .toast-error .toast-title {
  font-size: 14px;
  white-space: pre-line;
}

.etl-states-table td .toast-error .toast-message {
  white-space: pre-line;
}

.etl-states-table tr {
  border-left: 5px solid transparent;
}

.etl-states-table tr.edited {
  border-left: 5px solid red;
}

.etl-states-table tr.edited .value-cell {
  font-style: italic;
  background: #ffffe0;
}

.etl-states-table tr .local-date-time {
  font-style: italic;
  color: $grey;
}

.portal-tabs .refresh-actions {
  display: flex;
}
.portal-tabs .refresh-actions .auto-refresh-warning {
  display: flex;
  flex-grow: 1.1;
  margin: 5px;
}
.portal-tabs .refresh-actions .auto-refresh-warning .alert-warning {
  margin: 0 auto;
}
.portal-tabs .refresh-actions .btn-group {
  margin-top: 15px;
  min-width: 90px;
}



