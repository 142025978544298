@import "ri-global.scss";
/* defaults
-------------------------------------------------- */

.input {
  display: flex;
  padding: 0 9px;
  /*background-color: #eaf4f5;*/
  /*border-radius: 3px;*/
  color: $grey;
}

input[type='text']  {
  background-color: inherit;
  padding: 0 9px;
  border: none;
  font-size: inherit;
  outline: none;
  font-family: inherit;
  color: $dark-grey;
}

input[type='text']:focus  {
  color: black;
}

label {
  display: block;
}

input::placeholder {
  color: $grey;
  opacity: 0.8;
}

.input-search {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(204, 204, 204);
  padding: 0 0;
  color: $grey;
}
.input-search svg {
  color: $dark-grey;
}

.inputWithDarkBackground {
  color: $grey !important;
}

.inputWithDarkBackground input:focus {
  color: $dark-grey !important;
}
