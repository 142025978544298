@import "ri-global.scss";
.queryBuilder select {
  width: auto;
}

.qb-combinators {
  position: relative;
  z-index: 5;
}

.qb-dropdown-selector.qb-combinators {
  background: #f5f5f5;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid #e8e8e8;
  .caret {
    color: #a8a8a8;
  }
}

.qb-rule-group {
  .qb-rule-group {
    margin-left: 15px;
  }
  padding: 10px 10px 6px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  position: relative;

  .ruleGroup-header {
    padding: 5px 0px;
  }

  .rule-summary {
    visibility: hidden;
    opacity: 0;
    font-weight: bold;
    font-size: 12px;
  }

  .rule-actions,
  .ruleGroup-actions {
    position: relative;
    left: 5px;
    display: inline;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in;
    -webkit-transition: opacity 0.3s ease-in;
  }

  .rule-actions {
    margin-right: 5px;
    top: 10px;
  }

  .qb-rule:hover > .rule-actions,
  &:hover .ruleGroup-actions {
    visibility: visible;
    opacity: 1;
  }

  &.ruleGroup-minimized .rule-summary {
    visibility: visible;
    opacity: 1;
  }

  .ruleGroup-minimize {
    float: right;
  }

  .qb-add-rule,
  .qb-add-group {
    display: inline-block;
    position: relative;
    padding: 3px 5px;
    margin: 5px 0px 0px 5px;
  }

  .qb-add-rule {
    margin-left: 25px;
  }

  input.qb-group-label[type='text'] {
    height: 27px;
    font-size: 13px;
    font-weight: bold;
    width: '150px';

    &:focus {
      background-color: #d4d4d4;
      border-radius: 1px;
    }

    &:hover {
      background-color: #d4d4d4;
      height: 27px;
      border-radius: 1px;
    }

    &::placeholder {
      color: #aaaaaa;
    }
  }
}

.qb-rule {
  display: flex;

  .rule-selections-wrapper {
    position: relative;
    margin: 4px 4px 4px 15px;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #eee;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-grow: 1.1;

    .dropdown {
      height: 22px;
      .qb-dropdown-selector {
        border-color: transparent;
        display: inline-block;
        margin-right: 10px;
      }
    }

    .qb-applied-value {
      position: relative;
      background: $dark-grey;
      word-break: break-word;
      display: inline-block;
      padding: 1px 5px;
      font-size: 12px;
      line-height: 1.6;
      border-radius: 3px;
      color: white;
    }
  }
}

// Disabled Rule, Rule group
.rule-disabled,
.ruleGroup-disabled {
  .qb-combinators,
  .rule-selections-wrapper {
    &,
    textarea,
    .qb-dropdown-selector,
    .qb-applied-value {
      color: #ccc;
      background: #eee;
    }
  }
}
// end

// Add connecting tree lines
@mixin add-tree-line {
  content: '';
  position: absolute;
  left: -10px;
  width: 10px;
  border-color: #b4b4b4;
  border-style: solid;
  z-index: 1;
}

.queryBuilder {
  .qb-rule .rule-selections-wrapper,
  .qb-rule-group .qb-rule-group {
    &::before,
    &::after {
      @include add-tree-line();
    }

    &::before {
      top: -29px;
      height: calc(100% + 30px);
      border-width: 0 0 0 2px;
    }

    &::after {
      top: 0;
      height: calc(50%);
      border-width: 0 0 2px 0px;
    }
  }
  .qb-add-rule::before {
    @include add-tree-line();
    top: -29px;
    height: calc(50% + 30px);
    border-width: 0 0 2px 2px;
    width: 20px;
    left: -21px;
  }
}
// end

// button.ruleGroup-minimize.btn {
//   &,
//   &:focus,
//   &:active {
//     outline: none;
//   }
// }

// .ruleGroup-actions button:focus,
// .rule-actions button:focus {
//   outline: 0 !important;
// }

.queryBuilder .rule .qb-in-edit-value {
  display: flex;
  position: relative;
  padding-right: 20px;
  flex-grow: 1.1;
  button.value-clear-button {
    &,
    &:focus {
      position: absolute;
      top: 1px;
      right: 0px;
      font-size: 12px;
      height: 20px;
      padding: 2px 4px;
      line-height: 1;
      color: #cccccc;
      z-index: 10;
    }
    &:hover {
      color: $font-color;
    }
  }

  .search_search {
    width: 100%;
    li:not(:last-child) {
      border-bottom: 1px solid $light-grey;
    }

    .dropdown-menu > li > a {
      white-space: normal;
    }

    .rbt-input {
      padding: 0px;
    }

    input.rbt-input-hint,
    input[type='text'].rbt-input-main {
      font-size: 13px;
      height: 22px;
    }
  }
}
