@import "ri-global.scss";
.cme-inspector {
  width: 95%;
  margin: 0px auto;

  .cme-inspector-general-help {
    font-size: 14px;
    margin-left: 5px;
  }

  .cme-inspector-title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
  }

  .cme-inspector-group-buttons {
    height: 46px;
  }

  .cme-inspector-inline-form-field {
    width: 48%;
    margin-left: 0;
    margin-right: 15px;
    display: inline-block;
    vertical-align: top;
  }

  .cme-inspector-radio {
    font-size: 14px;
    label {
      display: flex;
      align-items: center;
      padding-bottom: 8px;

      input {
        margin-right: 5px;
      }
    }
  }

  .cme-inspector-result{
    font-size: 16px;

    .cme-inspector-result-errors {
      margin: 15px 0;
    }

    .cme-inspector-result-header {
      margin-bottom: 10px;
    }

  }

  .cme-inspector-row-1 {
    background-color: $background-grey;
  }
  .cme-inspector-row-2 {
    background-color: $light-grey;
  }

  .cmi-inspector-merchant-label {
    padding: 5px 0;
  }

  .cme-inspector-table {
    .cme-inspector-first-description-cell {
      font-size: 17px;
      font-weight: bold;
    }

    tbody>tr:hover {
      background: $middle-grey;
    }
  }
}

#cme-inspector-page-help {
  max-width: 400px;
}
