@import "ri-global.scss";
.report-action-row {
  padding-bottom: 20px;
}

.group-edit-general-tab input[type='text'].form-control,
.group-edit-feeds-tab input[type='text'].form-control,
.group-edit-feeds-tab input[type='password'].form-control {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 0px;
  box-shadow: none;
}

.group-edit-general-tab input[type='text'].form-control.unedited,
.group-edit-feeds-tab input[type='text'].form-control.unedited,
.group-edit-feeds-tab input[type='password'].form-control.unedited {
  background-color: #EDEDED;
}

.group-edit-general-tab .control-label,
.group-edit-feeds-tab .control-label {
  height: 34px;
}

.group-edit-general-tab input[type='text'].form-control:disabled,
.group-edit-feeds-tab input[type='text'].form-control:disabled,
.group-edit-feeds-tab input[type='password'].form-control:disabled {
  background-color: #eeeeee;
}

.group-edit-general-tab .has-error .cm-sql-query-sf-input,
.group-edit-general-tab .has-error input[type='text'].form-control,
.group-edit-feeds-tab .has-error .cm-sql-query-sf-input,
.group-edit-feeds-tab .has-error input[type='text'].form-control,
.group-edit-feeds-tab .has-error input[type='password'].form-control {
  border-color: $secondary-color;
}

.group-edit-feeds-tab .has-error .cm-sql-query-sf-input {
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ce8483;
}

.group-edit-feeds-tab .has-error .checkbox,
.group-edit-feeds-tab .has-error .checkbox-inline,
.group-edit-feeds-tab .has-error .control-label,
.group-edit-feeds-tab .has-error .form-control-feedback,
.group-edit-feeds-tab .has-error .help-block,
.group-edit-feeds-tab .has-error .radio,
.group-edit-feeds-tab .has-error .radio-inline,
.group-edit-feeds-tab .has-error.checkbox label,
.group-edit-feeds-tab .has-error.checkbox-inline label,
.group-edit-feeds-tab .has-error.radio label,
.group-edit-feeds-tab .has-error.radio-inline label {
  color: $secondary-color;
}

.group-edit-feeds-tab .has-warning input[type='text'].form-control {
  border-color: #8a6d3b;
}

.group-edit-feeds-tab .has-success input[type='text'].form-control {
  border-color: #3c763d;
}

.group-edit-feeds-tab .report-form-header .report-id {
  text-transform: uppercase;
  font-size: 12px;
  color: #999999;
}

.group-edit-feeds-tab .report-form-header .report-name {
  font-size: 24px;
  font-weight: 300;
}


.group-edit-general-tab form.form-horizontal,
.group-edit-feeds-tab form.form-horizontal {
  padding: 10px;
}

.group-edit-general-tab form.form-horizontal.dirty h4,
.group-edit-feeds-tab form.form-horizontal.dirty h4 {
  font-style: italic;
}

.group-edit-general-tab form.form-horizontal.dirty h4::after,
.group-edit-feeds-tab form.form-horizontal.dirty h4::after {
  content: '*';
  font-weight: bold;
}

.GroupEditPage__toggle {
  display: inline-block;
  width: 200px;
  vertical-align: middle;
}
