@import "ri-global.scss";
.qc-metrics {
  .qc-metris-warning {
    font-size: 18px;
    margin-top: 100px;
    text-align: center;
  }

  .qc-metrics-menu {
    padding: 15px 15px 0 15px;

    .qc-metrics-menu-label {
      vertical-align: middle;
      font-weight: bold;
    }
  }

  .qc-metrics-widget-header {
    text-align: center;
    margin: 5px 0 10px 0;

    .qc-metrics-widget-header-label {
      font-size: 16px;
      font-weight: bold;
      vertical-align: middle;
    }
  }

  .qc-metrics-graph-container {
    min-height: 400px;
  }

  .qc-metrics-widget-query {
    padding: 5px 0 5px 15px;

    .qc-metrics-widget-query-buttons {
      display: inline-block;
      height: 45px;
      vertical-align: middle;
    }
  }

  .qc-metrics-no-message {
    font-size: 18px;
    text-align: center;
    padding-top: 100px;
  }

  .qc-metrics-widget {
    border-bottom: 1px solid $light-grey;
    margin: 20px 0 30px 0;
    padding-top: 10px;
  }
}
