@import "ri-global.scss";
.category-guideline-view-field {
  height: 100%;
  overflow: auto;
  padding: 20px;
}


.category-guideline-edit-field {
  resize: none;
}

.category-guideline-modal-label {
  font-size: 1.1em;
  text-align: center;
}
