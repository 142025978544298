@import "ri-global.scss";
.date-range-filter {
  display: inline-block;
  vertical-align: middle;

  .date-range-filter-body {
    padding: 6px 27px 6px 10px;

    border-radius: 4px;
    line-height: 1.42857143 !important;
    margin: 0;

    .spanRightArrowIcon {
      margin-top: -0.6em;
    }

    .date-range-filter-placeholder {
      color: $light-grey;
      font-style: italic;
    }
  }

  .filterSelector {
    color: inherit;
  }

  .squareBoxWithArrow {
    position: relative;
    background: white;
    z-index: 1000;
    border: 1px solid $light-grey;
    display: inline-block;
    margin-right: 12px;
    padding: 6px 32px 6px 12px;
    font-size: 14px;
    line-height: 16px;
    color: inherit;
  }

  .squareBoxWithArrow:hover {
    border: 1px solid $grey;
    cursor: pointer;
  }

  .squareBoxWithArrow:active {
    border: 1px solid $primary-color;
  }

  .spanRightArrowIcon {
    position: absolute;
    margin-top: -0.7em;
  }

  .defaultArrowDown {
    fill: $grey;
  }

  .squareBoxWithArrow:hover > span > svg {
    fill: $dark-grey;
  }
}

.date-range-filter-popup {
  position: absolute;
  z-index: 10001;
  border: 1px solid $primary-color;
  background-color: white;
  padding: 10px;

  // rewrite default DateRangePicker style
  .rdrDefinedRangesWrapper {
    width: 200px;
  }

  .date-range-filter-popup-available {
    font-size: 10px;
  }

  .date-range-filter-popup-available-info {
    margin-left: 2px;
    margin-right: 2px;
  }
}

// mark field as invalid in form
.has-error .date-range-filter-body {
  border-color: $red-dark-color;
}
