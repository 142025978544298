@import "ri-global.scss";
.home-page-header {
  margin-bottom: 18px;
  min-height: 48px;
}

.home-page-header .right-panel {
  float: right;
  margin-left: 30px;
}

.home-page-header .right-panel:after {
  clear: both;
}

.table-wrapper {
  background-color: white;
  border: 1px solid lightgray;
  width: 100%;
  margin-bottom: 18px;
  min-height: 310px;
}

.media-body .company-website {
  font-size: small;
  color: gray;
  text-transform: lowercase;
  display: inline-block;
}

.media-heading.company-name {
  text-transform: capitalize;
}
