@import "ri-global.scss";
.category-test-result-tooltip-failed,
.category-test-result-cell-failed {
  color: $red-color;
  font-weight: bold;
}

.category-test-result-tooltip-pass {
  color: MediumSeaGreen;
  font-weight: bold;
}

#category-test-trend-result-tooltip .tooltip-inner {
  max-width: 600px;

  table tr > td {
    padding: 0 10px 0 0;
  }
}

.category-test-result-tooltip-section:not(:last-child) {
  padding-bottom: 15px;
}

#category-test-cell-pass-tooltip .tooltip-inner {
  max-width: 600px;

  td {
    padding: 0;
  }

  td.category-test-cell-pass-tooltip-name {
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
  }

  td.category-test-cell-pass-tooltip-name span:after {
    content: ' ...................................';
  }
}
