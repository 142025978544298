@import "ri-global.scss";
@keyframes beacon-outer-ir6T {
  0% {
    transform: scale(1)
  }
  45% {
    opacity: 0;
    transform: scale(.75)
  }
  to {
    opacity: .9;
    transform: scale(1)
  }
}
@keyframes beacon-inner-1ZCk {
  20% {
    opacity: .9
  }
  90% {
    opacity: .7
  }
}

.outerBeacon {
  position: absolute;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  background-color: $primary-color-opacity_1;
  cursor: pointer;
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: beacon-outer-ir6T 2.2s infinite ease-in-out;
}

.middleBeacon {
  width: 19.3px;
  height: 19.3px;
  background-color: $primary-color-opacity_2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: joyride-beacon-inner-1ThC 1.2s infinite ease-in-out;
}

.innerBeacon {
  width: 5.6px;
  height: 5.6px;
  background-color: $primary-color;
  border-radius: 50%;
  animation: joyride-beacon-inner-1ThC 1.2s infinite ease-in-out;
}
