@import "ri-global.scss";
.menuList {
  min-width: 100%;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
}

.menuList ul {
  width: 100%;
}

.menuList > li > span,
.menuList > li > a {
  display: block;
  padding: 9px 18px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: $dark-grey;
  white-space: nowrap;
  font-size: 14px;
  text-align: center;
}

.menuList > li > span:hover,
.menuList > li > a:hover {
  text-decoration: none;
  background-color: $background-grey;
}
