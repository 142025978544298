@import "ri-global.scss";
.delivery-schedule {
  width: 100%;
  text-align: center;
  margin: 30px 0px;
}

.delivery-schedule .number-input-box input.form-control {
  width: 40px;
  display: inline-block;
  text-align: center;
}

.delivery-schedule .total-deliveries {
  margin-bottom: 12px;
}

.ds-dt-wrapper {
  width: 100%;
  padding: 0px 75px;
  margin: auto;
  border: 0px solid red;
  min-height: 100px;
  position: relative;
}

.ds-dt-wrapper .top-box {
  height: 12px;
  border: 1px solid $grey;
  border-bottom: none;
  width: 100%;
}

.ds-dt-wrapper .icon-box {
  margin: 6px 0px;
  height: 30px;
  position: relative;
}

.ds-dt-wrapper .deliverable-icon {
  margin-right: 12px;
}

.ds-dt-wrapper .deliverable-icon:first-child {
  position: absolute;
  left: -8px;
  margin-right: 0px;
}

.ds-dt-wrapper .deliverable-icon:last-child {
  position: absolute;
  right: -8px;
  margin-right: 0px;
}


.ds-dt-wrapper .single-date-input-btn {
  display: block;
  margin: auto;
  width: 100px;
  text-align: center;
}

.ds-dt-wrapper .single-date-input-btn:disabled {
  background: inherit;
  border: inherit;
  color: $dark-grey;
  opacity: 1;
}

.ds-dt-wrapper .first-date-input,
.ds-dt-wrapper .last-date-input {
  width: 120px;
  position: absolute;
  height: 48px;
  margin-bottom: 10px;
}

.ds-dt-wrapper .first-date-input {
  left: 0px;
}

.ds-dt-wrapper .first-date-input.uneditable:hover,
.ds-dt-wrapper .first-date-input.uneditable:focus {
  border-color: $light-grey;
}

.ds-dt-wrapper .last-date-input {
  right: 0px;
}

.ds-dt-wrapper .arrow-right {
  width: calc(100% - 125px);
  border: none;
  border-top: 2px solid $grey;
  color: $grey;
  height: 18px;
  margin-top: 24px;
  margin-left: 75px;
  margin-bottom: 18px;
  position: relative;
}

.ds-dt-wrapper .arrow-right svg {
  position: absolute;
  right: -20px;
  top: -10px;
  transform: rotateZ(90deg) scale(1.5);
}

.ds-dt-wrapper .bottom-connector {
  width: 2px;
  border-left: 1px dashed $grey;
  height: 60px;
  margin: auto;
}

.ds-dt-wrapper .bottom-box {
  min-height: 100px;
  border: 1px dashed $grey;
  width: calc(100% + 150px);
  margin-left: -75px;
  padding: 30px;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  color: $dark-grey;
}

.ds-dt-wrapper .bottom-box .number-input-box {
  display: inline-block;
}

.delivery-schedule .help-block {
  font-size: 10px;
  color: $font-color;
}
