@import "ri-global.scss";
.dateRange-filter {
  position: relative;
  display: inline-block
}

.dateRange-filter .warning {
  padding: 10px;
  margin-top: 10px;
}

.dateRange-filter .filter-popup .items {
  margin-top: 5px;
}

.dateRange-filter .filter-popup {
  position: absolute;
  background-color: white;
  padding: 18px;
  width: 600px;
  max-width: 700px;
  max-height: 480px;
  overflow-y: auto;
  margin-top: 0px;
  margin-left: 0em;
  border: 1px solid $primary-color;
  position: absolute;
  z-index: 10;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: slideup;
  animation-name: slideup;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.rdr-Days span {
  float: left
}

.dateRange-filter .filter-popup .item {
    padding-top: 10px;
    padding-bottom: 10px;
    /* padding-right: 0.65em; */
    -webkit-box-flex: 22%;
    -webkit-flex: 22%;
    -ms-flex: 22%;
    flex: 22%;
}

span.rdr-Day.is-passive {
  opacity: 1 !important;
  color: $light-grey !important;
}

.CheckBox {
    height: 16px;
    border-radius: 3px;
    cursor: pointer;
}

.ArrowRotationStop > svg {
  transform: rotate(0deg);
  transition: 400ms transform;
}

.ArrowRotationStart > svg {
  animation: spin 400ms;
  transform: rotate(90deg);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(90deg);
    }
}

.quickOptions {
  padding: 20px;
  text-align: center;
  flex-wrap: wrap;
}

.clearSelection {
  text-align: left;
  padding: 10px;
}

.dateRange-filter {
  position: relative;
  display: inline-block
}

:global .DayPicker {
  display: flex;
  justify-content: center;
  position: relative;
  /*padding: 1rem 0;*/
  user-select: none;
  outline: none;
}

:global .DayPicker-Month {
  display: table;
  border-collapse: separate;
  border-spacing: 3px;
  user-select: none;
  margin: 0 1rem;
}

:global .DayPicker-NavBar {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 .5rem;
}

:global .DayPicker-NavButton {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

:global .DayPicker-NavButton--prev {
  left: 1rem;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjI2cHgiIGhlaWdodD0iNTBweCIgdmlld0JveD0iMCAwIDI2IDUwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnNrZXRjaD0iaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoL25zIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy4zLjIgKDEyMDQzKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5wcmV2PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc2tldGNoOnR5cGU9Ik1TUGFnZSI+CiAgICAgICAgPGcgaWQ9InByZXYiIHNrZXRjaDp0eXBlPSJNU0xheWVyR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzLjM5MzE5MywgMjUuMDAwMDAwKSBzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC0xMy4zOTMxOTMsIC0yNS4wMDAwMDApIHRyYW5zbGF0ZSgwLjg5MzE5MywgMC4wMDAwMDApIiBmaWxsPSIjNTY1QTVDIj4KICAgICAgICAgICAgPHBhdGggZD0iTTAsNDkuMTIzNzMzMSBMMCw0NS4zNjc0MzQ1IEwyMC4xMzE4NDU5LDI0LjcyMzA2MTIgTDAsNC4yMzEzODMxNCBMMCwwLjQ3NTA4NDQ1OSBMMjUsMjQuNzIzMDYxMiBMMCw0OS4xMjM3MzMxIEwwLDQ5LjEyMzczMzEgWiIgaWQ9InJpZ2h0IiBza2V0Y2g6dHlwZT0iTVNTaGFwZUdyb3VwIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K");
}

:global .DayPicker-NavButton--next {
  right: 1rem;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjI2cHgiIGhlaWdodD0iNTBweCIgdmlld0JveD0iMCAwIDI2IDUwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnNrZXRjaD0iaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoL25zIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy4zLjIgKDEyMDQzKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5uZXh0PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc2tldGNoOnR5cGU9Ik1TUGFnZSI+CiAgICAgICAgPGcgaWQ9Im5leHQiIHNrZXRjaDp0eXBlPSJNU0xheWVyR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuOTUxNDUxLCAwLjAwMDAwMCkiIGZpbGw9IiM1NjVBNUMiPgogICAgICAgICAgICA8cGF0aCBkPSJNMCw0OS4xMjM3MzMxIEwwLDQ1LjM2NzQzNDUgTDIwLjEzMTg0NTksMjQuNzIzMDYxMiBMMCw0LjIzMTM4MzE0IEwwLDAuNDc1MDg0NDU5IEwyNSwyNC43MjMwNjEyIEwwLDQ5LjEyMzczMzEgTDAsNDkuMTIzNzMzMSBaIiBpZD0icmlnaHQiIHNrZXRjaDp0eXBlPSJNU1NoYXBlR3JvdXAiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=");
}

:global .DayPicker-Caption {
  display: table-caption;
  height: 1.5rem;
  text-align: center;
  font-size: 1.2em;
}

:global .DayPicker-Weekdays {
  display: table-header-group;
  font-family: 'Roboto', "Helvetica Neue", Arial, sans-serif;
}

:global .DayPicker-WeekdaysRow {
  display: table-row;
}

:global .DayPicker-Weekday {
  display: table-cell;
  padding: .5rem;
  font-size: .875em;
  text-align: center;
  color: #8b9898;
}

:global .DayPicker-Body {
  display: table-row-group;
}

:global .DayPicker-Week {
  display: table-row;
}

:global .DayPicker-Day {
  display: table-cell;
  padding: .5rem;

  margin: .1em;
  background-color: rgb(242, 242, 242);
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  border: 1px solid white;
  outline: none;
}

:global .DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

/* Default modifiers */

:global .DayPicker-Day--today {
  color: #d0021b;
  font-weight: 500;
}

:global .DayPicker-Day--from {
  color: white;
  background-color: rgb(44, 159, 179);
}

:global .DayPicker-Day--disabled {
  cursor: default;
  opacity: 0.5;
}

:global .DayPicker-Day--outside {
  cursor: default;
  color: #dce0e0;
}

/* Example modifiers */

:global .DayPicker-Day--sunday {
  color: #dce0e0;
  background-color: #f7f8f8;
}

:global .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: rgb(44, 159, 179);
  border: 1px solid rgb(44, 159, 179);
  background-color: white;
}

:global .DayPicker-Day--selected.DayPicker-Day--from:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
:global .DayPicker-Day--selected.DayPicker-Day--to:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: rgb(44, 159, 179);
  color: white;
  font-weight: bold;
}

.date-search-container {
  margin-bottom: 10px;
  display: flex;
  color: $dark-grey;
  width: 96%;
  padding-left: 4%;
}

.action {
  padding-left: 76%;
}

.date-search {
  color: $dark-grey;
  padding-left: 3%;
  padding-right: 3%;
  border: none;
  border-bottom: 1px solid lightgray;
  text-align: center;
  font-size: 14px;
  outline: none;
  text-transform: uppercase;
}

.date-search:active {
  border: none;
  border-bottom: 1px solid lightgray;
}

.date-search::-webkit-inner-spin-button {
    display: none;
}

.date-search::-webkit-calendar-picker-indicator {
  display: none;
}

.date-search::-webkit-datetime-edit-text {
  color: lightgray;
}

.search-divider-text {
  text-align: center;
  padding-left: 4%;
  padding-right: 4%;
}

.search-errors {
  color: rgba(255, 0, 0, 0.75);
  text-align: center;
  width: 100%;
}

.report-date-quick-options {
  text-align: left;
  margin-left: -18px;
  margin-top: -18px;
  margin-right: -18px;
  margin-bottom: 18px;
  background-color: $primary-color;
}

.reports-date-quick-option {
  display: inline-block;
  padding-right: 18px;
  padding: 12px 0px 12px 18px;
  color: white;
  opacity: 0.8;
}

.quick-select-active {
  opacity: 1;
  font-weight: bold;
}

.reports-date-quick-option:hover {
  cursor: pointer;
  text-decoration: underline;
}
