@import "ri-global.scss";
.FeedEditPage__error {
  color: red;
}

.FeedEditPage__toggle_label {
  width: 130px;
}

.FeedEditPage__delivery_icon_warn {
  color: $yellow-color;
}

.FeedEditPage__toggle {
  display: inline-block;
  width: 200px;
  vertical-align: middle;
}

.group-input {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.group-input > span {
  margin-right: 90px;
}

.group-input > input[type='text'] {
  width: 165px;
  height: 30px;
}

.bg-white {
  background-color: white;
}

/* send increment modal daypicker color override */
.modal-content .overlay {
  color: black;
}

.feed-module-input {
  display: flex;
  align-items: center;
}

.feed-module-link {
  margin-left: 20px;
  margin-bottom: 10px;
}

.group-link {
  margin-left: 10px;
}
