@import "ri-global.scss";
.radio-filter {
  position: relative;
  display: inline-block;
}

.radio-filter .warning {
  padding: 10px;
  margin-top: 10px;
}

.radio-filter .filter-popup .items {
  margin-top: 5px;
}

.radio-filter-search-input {
  margin: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-filter .SquarePopoverSlice {
  padding: 6px;
}

.SquarePopoverSlice {
  position: absolute;
  background: white;
  z-index: 1060;
  border: 1px solid $primary-color;
  display: block;
}

.bottomAnimation {
  position: absolute;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: slideup;
  animation-name: slideup;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.bottom.start {
  left: 0;
  margin-top: 0px;
}

.radio-filter .filter-popup {
  position: absolute;
  background-color: white;
  margin-top: 18px;
  padding: 18px;
  max-width: 700px;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-left: 0em;
  border: 1px solid $primary-color;
  position: absolute;
  z-index: 10;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: slideup;
  animation-name: slideup;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.radio-filter .filter-popup .item {
  padding-top: 10px;
  padding-bottom: 10px;
  /* padding-right: 0.65em; */
  -webkit-box-flex: 22%;
  -webkit-flex: 22%;
  -ms-flex: 22%;
  flex: 22%;
}

.CheckBox {
  height: 16px;
  border-radius: 3px;
  cursor: pointer;
}

.ArrowRotationStop > svg {
  transform: rotate(0deg);
  transition: 400ms transform;
}

.ArrowRotationStart > svg {
  animation: spin 400ms;
  transform: rotate(90deg);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
