@import "ri-global.scss";
.CheckBoxMultiSelect.container {
  margin: 10px;
  position: relative;
}
.CheckBoxMultiSelect .typeContainer {
  display: flex;
  flex: 1;
}

.CheckBoxMultiSelect .multiLabel {
  width: 110px;
  font-size: 12px;
  text-transform: uppercase;
}

.CheckBoxMultiSelect .items {
  margin-left: 110px;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 5px;
}

.CheckBoxMultiSelect .item {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0.65em;
  flex: 50%;
}

/* Target IE 9+ */
@media screen and (min-width:0\0) {
  .CheckBoxMultiSelect .item {
    flex: 46%;
  }
  .CheckBoxMultiSelect .productInput {
    flex: 330px;
  }
}

.CheckBoxMultiSelect input {
  /*background-color: rgb(234, 244, 245);*/
  height: 45px;
}

.CheckBoxMultiSelect .actions {
  position: absolute;
  left: 0;
  top: 40px;
}

.CheckBoxMultiSelect .actions a {
  margin-bottom: 6px;
  font-size: 12px;
}

.CheckBoxMultiSelect .selectedNames {
  background-color: #f5f5f5;
  text-align: left;
  width: 35%;
  margin: 10px;
  padding: 20px;
}

.CheckBoxMultiSelect .selectedNames .namesContainer {
  max-height: 200px;
  overflow: scroll;
}

.CheckBoxMultiSelect .selectedNames > label {
  font-weight: bold;
  margin-bottom: 10px;
}

.CheckBoxMultiSelect .selectedNames .namesContainer > div {
  margin-top: 6px;
}

.CheckBoxMultiSelect .boxContainer {
  width: 100%;
}

.CheckBoxMultiSelect .boxContainer.withSelectedNames {
  display: flex;
  flex-direction: column;
  width: 65%;
  overflow: scroll;
}

.CheckBoxMultiSelect .visibilityStyle {
  color: $primary-color;
  font-weight: bold;
  cursor: pointer;
}

.CheckBoxMultiSelect .warning {
  padding: 10px;
  margin-top: 10px;
}
