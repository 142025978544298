@import "ri-global.scss";
.productHeader {
  background-color: white;
  line-height: 32px;
  padding: 10px 20px;
  margin-bottom: 2px;
}

.productTitle {
  font-size: 20px;
  display: inline-block;
  margin-left: 10px;
}

.checkboxInline {
  display: inline-block;
  position: relative;
  top: -2px;
  padding-right: 4px;
}

.productShow {
  float: right;
  position: relative;
  cursor: pointer;
}

.productContent {
  background-color: white;
  padding: 5px 10px;
}

.listOptionsGroup {
  padding: 15px 15px;
  max-height: 300px;
  overflow-y: auto;
}

.pointerCursor {
  cursor: pointer;
}

.categoryName {
  margin-top: 7px;
}

.email-heading {
  font-size: 20px;
  padding-left: 20px;
}

.email-eligibility {
  margin-left: 20px;
}

.email-eligibility span {
  margin-right: 160px;
}

.categoryL1-display {
  clear: both;
  /* padding-top: 10px; */
  margin-left: 20px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
}

.categoryL2-display {
  display: inline-block;
  margin-top: 6px;
}

.checkboxClass {
  margin-top: 14px;
  margin-left: 10px;
}

.categoryBlock:not(:first-child) {
  clear: both;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgb(204, 204, 204);
}

.disable-panel {
  pointer-events: none;
  opacity: 0.4;
}
