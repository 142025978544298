@import "ri-global.scss";
.report-filter-list .squareBoxWithArrow:hover {
  border: 1px solid $grey;
  cursor: pointer;
}

.report-filter-list .squareBoxWithArrowSelected:hover {
  border: 1px solid $primary-color;
  cursor: pointer;
}

.report-filter-list .squareBoxWithArrow:hover .spanRightArrowIcon,
.report-filter-list .squareBoxWithArrow:hover .defaultArrowDown,
.report-filter-list .squareBoxWithArrow:hover .defaultArrowUp {
  fill: black;
}

.report-filter-list .squareBoxWithArrow,
.report-filter-list .squareBoxWithArrowSelected {
  position: relative;
  background: white;
  z-index: 1000;
  border: 1px solid $light-grey;
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 6px 2px 6px 12px;
  font-size: 14px;
  line-height: 16px;
  color: $dark-grey;
  display: flex;
  flex-direction: row;
}

.report-filter-list .squareBoxWithArrowSelected {
  border: 1px solid $primary-color;
}

.report-filter-list .staged .squareBoxWithArrowSelected div,
.report-filter-list .staged .squareBoxWithArrow div {
  font-style: italic;
  color: $dark-grey;
}
.report-filter-list {
  @keyframes slideup {
    from {
      opacity: 0;
      transform: translateY(20px);
      -webkit-transform: translateY(20px);
    }

    to {
      opacity: 1;
      transform: translateY(0%);
      -webkit-transform: translateY(0%);
    }
  }
}

.spanRightArrowIconFilter {
  margin-top: -0.2em;
}

.report-title {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  width: 70%;
  font-size: 24px;
  line-height: 28px;
  color: $dark-grey;
  text-transform: capitalize;
  font-weight: 300;
}

.title {
  margin-top: 30px;
  margin-bottom: 30px;
}

.report-filter-list {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.ctm-reports-view-page .linkButton {
  color: $button-secondary-color;
  font-weight: bold;
  cursor: pointer;
  padding: 6px 12px;
  font-size: 14px;
  line-height: inherit;
  height: 30px;
  float: right;
}

.ctm-reports-view-page .linkButton:hover {
  color: $primary-color;
  text-decoration: underline;
}

.filter-actions-container #resetButton {
  margin-right: 12px;
}

.moreFilters {
  color: $primary-color;
  font-weight: bold;
  cursor: pointer;
  line-height: 28px;
}

.fewerFilters {
  color: $primary-color;
  font-weight: bold;
  cursor: pointer;
  line-height: 28px;
}

.ctm-reports-view-page input.form-control {
  border: 0px !important;
  border-radius: 0px !important;
  height: 30px;
}

.more-filters div.userDefinedBox {
  height: 32px !important;
}

.userDefinedBox {
  height: 32px;
  display: inline-flex;
  border: 1px solid $light-grey;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px;
  min-width: 250px;
  position: relative;
  background-color: white;
  font-size: 14px;
}
.userDefinedBox:hover {
  display: inline-flex;
  border: 1px solid grey;
}

.userDefinedBox.staged input {
  font-style: italic;
}
.userDefinedBox.staged input:focus {
  font-style: normal;
}

.userDefined {
  height: 32px !important;
  border: 1px solid lightgrey;
  padding-left: 10px;
}

.btn_custom.apply,
.apply {
  padding: 6px 12px;
  font-size: 14px !important;
  font-weight: bold;
  height: 30px;
}

.ctm-reports-view-page {
  min-width: 770px;
}

.MT30 {
  margin-top: 30px;
}

.MB30 {
  margin-bottom: 30px;
}

.PL2 {
  padding-left: 2px;
}

.ML5 {
  margin-left: 5px;
}

.filter-actions-container {
  float: right;
  margin-right: 15px;
}

.more-filters {
  float: left;
  margin-left: 6px;
  margin-top: 3px;
}

.filterDetailsWrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.filterDescriptorTop {
  font-size: 10px;
  color: $font-color;
  line-height: 12px;
}

.periscope-report-wrapper {
  margin-top: 30px;
  margin-bottom: 80px;
}

.report-action-row {
  vertical-align: middle;
}

.report-action-row .glyphicon {
  margin-left: -12px;
}
