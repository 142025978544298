@import "ri-global.scss";
.suggestion-query {

  display: inline-flex;
  flex-direction: column;

  gap: 10px;

  .suggestion-query-field {
    display: flex;
    flex-direction: row;
    align-items: center;


    .suggestion-query-label {
      width: 50px;
    }


    .suggestion-query-input {
      width: 300px;
    }
  }

}

.suggestion-query-inline {
  flex-direction: row !important;
}
