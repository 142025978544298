@import "ri-global.scss";
.widget-wrapper {
  padding-top: 1em;
}

.widget-tabs {
  max-height: 50em;
  overflow: auto;
  padding-top: 2em;
}

.well-description {
  max-height: 105em;
}

.qc-tool-chart-tab-widget {
  width: 100%;
  height: 100%;
}

.well-description .container,
.well-overtime .container {
  width: auto;
}

.desc-stats {
  min-height: 85px;
}

svg.widget-toolbar-icon {
  fill: $grey;
  cursor: pointer;
}

svg.widget-toollbar-icon:hover,
svg.widget-toollbar-icon-active {
  fill: white;
}

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 1px solid rgba(161, 161, 161, 0.09);
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: $primary-color;
  border-color: $primary-color;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover,
.pagination > li > a,
.pagination > li > span {
  color: $primary-color;
}

.well-search-table {
  margin-bottom: 15px;
}

.temp-filter-select {
  cursor: pointer;
}
.table-widget {
  height: calc(100% - 35px);
  overflow: auto;
  margin-top: 35px;
}
.table-widget-header {
  position: absolute;
  width: 100%;
  top: 0px;
  padding: 3px 3px;
  height: 35px;
}

.description-table-actions,
.table-widget-header {
  background: #7a7a7a;
  color: white;
}

.description-table-actions .pull-right .action-button.active,
.table-widget-header .pull-right .btn.active,
.description-table-actions .pull-left.action-button.active {
  border-color: white;
}

.description-table-actions .pull-right .action-button,
.table-widget-header .pull-right .btn,
.description-table-actions .pull-left.action-button {
  background: transparent;
  color: white;
  border-color: transparent;
}

.description-table-actions .pull-left.bulk-edit-brand {
  margin-left: 10px;
}

.table-widget tr.unselected-filter {
  opacity: 0.6;
}

.table-widget tr.selected-filter {
  opacity: 1;
  color: #555555;
  font-weight: 600;
}

.table-widget .react-bs-container-body {
  margin-right: 10px;
  height: 100%;
  overflow: auto;
}

.table-widget .table-chart-toolbar .btn-group,
.qc-tool-chart-tab-widget .form-inline {
  margin-top: 5px;
  margin-right: 5px;
}

.loading-spinner.react-spinner {
  position: absolute;
  margin-top: 0px;
  width: 20px;
  height: 20px;
  z-index: 100;
}

.table-widget-header .table-title-wrapper {
  width: calc(100% - 100px);
  display: flex;
  min-width: auto;
  overflow: hidden;
}

h5.table-title {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  white-space: nowrap;
  display: flex;
}

h6.select-text {
  margin-top: 7px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 20px;
  white-space: nowrap;
  display: flex;
  color: white;
}

h6.select-text:hover {
  color: $primary-color;
  cursor: pointer;
}

.widget-table-container.loading .react-bs-container-body {
  opacity: 0.7;
}

.widget-table-options .refresh-widget-btn {
  margin-left: 15px;
}

.widget-table-options .select-size,
.description-table-option .select-size {
  width: 70px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}

.description-table-option .select-sort-by {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
}

.description-table-option .refresh-widget-btn {
  margin-left: 10px;
  float: right;
}

.description-table-option + hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

.column-selector {
  display: inline-block;
  margin-top: 12px;
  margin-right: 15px;
}

.column-selector .column-selector-box {
  width: 20px;
}

.well-description .container,
.well-overtime .container {
  width: auto;
}

.editReviewDescription {
  padding-bottom: 10px;
}

.editReviewTable {
  color: $font-color;
  font-size: 14px;
}

.editReviewTable,
.react-bs-container-header.table-header-wrapper {
  background: white;
}

.editReviewTable tr:nth-child(even) {
  background: white;
}

.editReviewTable .react-bs-table table th,
.editReviewTable .react-bs-table table td {
  font-size: 12px;
  white-space: normal;
}

.editReviewTable .react-bs-table-bordered {
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
  border-radius: 4px;
}

.editReviewTable .react-bs-container-body {
  border: 0px;
  border-radius: 0px 0px 4px 4px;
}

.no-data-msg {
  font-size: 17px;
  height: 300px;
  text-align: center;
  padding-top: 100px;
  font-style: normal;
}
.react-bs-table-no-data .no-data-msg {
  background: white;
  width: 100%;
}

.description-table-container.loading .no-data-msg {
  opacity: 1;
}

.widget-wrapper .react-bs-container-header {
  text-transform: uppercase;
  box-shadow: 0 8px 16px rgba($dark-grey, 0.2);
}

.widget-wrapper .sort-column .order > .caret,
.widget-wrapper .sort-column .order > .dropdown,
.widget-wrapper .sort-column .order > .dropup {
  visibility: hidden;
}
.widget-wrapper .sort-column .order > span.caret,
.widget-wrapper .sort-column .order.dropup {
  visibility: visible;
}

div.widget-wrapper .table > tbody > tr:nth-of-type(even) {
  background: #f8f8f8;

  transition: color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}

div.widget-wrapper .table > tbody > tr:nth-of-type(odd) {
  background: #fbfbfb;
  transition: color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}

div.widget-wrapper .table > tbody > tr > td,
div.widget-wrapper .table > tfoot > tr > td,
div.widget-wrapper .table > thead > tr > td {
  border-color: $light-grey;
}

div.widget-wrapper .table > tbody > tr:hover > td {
  background-color: white;
  color: black;
  transition: color 0.4s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}

div.widget-wrapper .react-bs-table-no-data {
  border: none;
}

div.widget-wrapper .react-bs-container-header,
div.widget-wrapper .react-bs-table-bordered,
div.widget-wrapper .react-bs-container-body {
  border-radius: 0px 0px 0px 0px;
  border: none;
}

div.widget-wrapper .react-bs-container-header {
  border-bottom: 1px solid rgba($dark-grey, 0.3);
}
.description-table-container .panel.panel-default,
.table-widget .panel.panel-default {
  margin-bottom: 0px;
  border-radius: 0px;
  border-right: none;
  border-left: none;
}

.widget-wrapper .table-condensed > tbody > tr > td,
.widget-wrapper .table-condensed > tbody > tr > th,
.widget-wrapper .table-condensed > tfoot > tr > td,
.widget-wrapper .table-condensed > tfoot > tr > th,
.widget-wrapper .table-condensed > thead > tr > td,
.widget-wrapper .table-condensed > thead > tr > th {
  padding: 2px;
}

.widget-wrapper .react-bs-table table td {
  white-space: normal;
}

.widget-filters-daterange {
  margin-left: 5px;
}

.widget-filters {
  margin-right: 0;
}

.description-table-actions .rbt .dropdown-menu > li > a {
  white-space: normal;
}

.search-desc-status {
  margin-left: 10px;
  margin-top: 5px;
}

.widget-enable-status {
  margin-right: 10px;
  margin-top: 5px;
  margin-left: 10px;
  margin-bottom: 5px;
  display: flex;
}

.data-summary-widget .widget-enable-status .refresh {
  border: none;
  margin-top: 4px;
}

.data-summary-header .search-qc-statusButton.inactive .statusMarker {
  background-color: lightgray;
}

.data-summary-header .option {
  margin-top: 3px;
}

.search-stats-status {
  margin-left: 10px;
  margin-top: 11px;
}

#refresh-load-info.tooltip .tooltip-inner {
  text-align: left;
  font-size: 13px;
}

#refresh-load-info.tooltip .tooltip-inner span {
  font-style: italic;
}

.confirm-modal.modal-lg.confirm-modal.modal-dialog.edit-review {
  overflow-y: auto;
  position: relative;
}
