@import "ri-global.scss";
@import 'styles/helpers/indents.scss';
@import 'styles/helpers/display.scss';
@import 'overwrite-react-bootstrap-table';
@import 'overwrite-react-select';
@import 'styles/helpers/text.scss';
@import 'styles/helpers/font-size.scss';
/* fonts
-------------------------------------------------- */

.regular {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
}

.medium {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
}

b,
body .bold,
body label.bold {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  font-weight: 700;
}

i,
.italic {
  font-style: italic;
}

/* global style
-------------------------------------------------- */

html,
body {
  -webkit-font-smoothing: antialiased;
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  font-size: 12px;
  color: $font-color;
  background-color: $background-grey;
  margin: 0;
  padding: 0px;
  position: relative;
  min-height: 100vh;
  /* min-width: 980px; */
  line-height: normal;
}

h1 {
  font-size: 24px;
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: $primary-color;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
  color: $primary-color;
}

/* clears the 'X' from Internet Explorer */
::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
::-webkit-search-decoration,
::-webkit-search-cancel-button,
::-webkit-search-results-button,
::-webkit-search-results-decoration {
  display: none;
}

.clearfix:after {
  content: ' ';
  display: block;
  clear: both;
}

label > input[type='radio'] {
  margin-top: 0px;
}

/* main layout
-------------------------------------------------- */

.container {
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.content {
  min-height: 100vh;
  width: 100%;
}

.app > .page-content {
  min-height: calc(100vh);
  padding-top: 60px;
  padding-left: 50px;

  // for fixed footer
  position: relative;
  padding-bottom: 30px; // height of fotter
}

.page-container {
  margin: 0 auto;
  width: 80%;
  padding-top: 48px;
  padding-right: 0;
  padding-left: 0;
  position: relative;
  min-height: calc(100vh - 135px);
}

.admin-container {
  width: 100%;
  position: relative;
}

.page-content > .container,
.admin-container > .container {
  padding: 24px;
}

.btn-apply-save {
  margin-right: 10px;
}

.btn_custom {
  color: white;
  background-color: $button-background-color;
  border: 0 0 0 0 !important;
  border-width: 0px !important;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 10px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn_custom[disabled] {
  box-shadow: 0px 0px 0px 0px;
  background-color: $light-grey;
}

.btn_custom[disabled]:hover {
  box-shadow: 0px 0px 0px 0px;
  color: $grey;
  cursor: not-allowed;
}

.btn_custom_secondary {
  color: $button-secondary-color;
  background-color: white;
  border: 1px solid $button-secondary-color;
  padding-left: 23px;
  padding-right: 23px;
  padding-top: 11px;
  padding-bottom: 11px;
  margin-right: 10px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
}
.btn_custom:hover,
.btn_custom:focus,
.btn_custom:active,
.btn_custom:active:focus {
  color: $button-color-hover;
  background-color: $primary-color;
}

.btn_custom_secondary:hover,
.btn_custom_secondary:focus,
.btn_custom_secondary:active,
.btn_custom_secondary:active:focus {
  color: $button-secondary-color;
  background-color: $button-secondary-background-hover;
  border: 1px solid $primary-color;
}

.btn_custom_red {
  color: white;
  background-color: #f06647;
  padding-left: 23px;
  padding-right: 23px;
  padding-top: 11px;
  padding-bottom: 11px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
}

.btn_custom_bg_red:hover,
.btn_custom_bg_red:focus,
.btn_custom_bg_red:active,
.btn_custom_bg_red:active:focus,
.btn_custom_bg_red {
  background-color: $secondary-color;
}

.btn_custom:hover,
.btn_custom_secondary:hover,
.btn_custom_red:hover {
  box-shadow: 0px 4px 8px 4px $light-grey;
  text-decoration: none;
  transition: box-shadow transform 0.2s ease-in;
  transform: scale(1.01);
}

.btn_custom_secondary[disabled] {
  box-shadow: 0px 0px 0px 0px;
  text-decoration: none;
  border: 1px solid $grey;
  color: $grey;
}

.btn_custom_secondary[disabled]:hover {
  box-shadow: 0px 0px 0px 0px;
  text-decoration: none;
  cursor: not-allowed;
}

.btn {
  outline-color: $primary-color !important;
}

.btn-info,
.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
}

.btn-info:hover,
.btn-info:active,
.btn-primary:hover,
.btn-primary:active {
  background-color: $secondary-color !important;
  border-color: $secondary-color;
}

.btn-info:focus,
.btn-primary:focus {
  background-color: $primary-color;
}

.btn-link,
.btn-link:focus {
  color: $primary-color;
}

.btn-link:hover {
  color: $light-primary-color;
}

.btn_medium {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 14px;
}

.btn_small {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 12px;
}

.btn_link {
  color: $primary-color;
  cursor: pointer;
}

.btn_link:hover {
  color: $primary-color;
  text-decoration: underline;
}

.btn-default + .active {
  background-color: $primary-color !important;
}

button[disabled] {
  color: $grey;
  cursor: default;
  background-color: $light-grey;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
}
/*button[disabled], html input[disabled] {
  cursor: default;
  opacity: 0.5;
}*/

html input[disabled] {
  cursor: default;
  opacity: 0.5;
}

.btn_custom + .btn_custom {
  margin-left: 10px;
}

.btn_custom + .btn_custom_secondary {
  margin-left: 10px;
}

.btn_custom_secondary + .btn_custom {
  margin-left: 10px;
}

.btn_custom_secondary + .btn_custom_secondary {
  margin-left: 10px;
}

.required::before {
  content: '* ';
  color: #f06647;
  margin-left: -8px;
  width: 8px;
}

/** Search input on IE11 */
input[type='text'] {
  background-color: transparent;
}

input[type='text'].input,
input[type='password'].input {
  padding: 6px 10px;
  border-radius: 5px;
  background-color: white;
  font-size: 14px;
  border: 2px solid #ddd;
  width: 200px;
}

input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.rightFormButton {
  float: right;
}

.rightFormButton + h3 {
  line-height: 33px;
}

.textRight {
  text-align: right;
}

.formTitle {
  font-size: 16px;
  line-height: 33px;
}

.formError {
  display: inline-block;
  margin-left: 10px;
  color: #ed765f;
}

table {
  border-spacing: 0px;
}

table td {
  padding: 0;
}

table td:first-child {
  padding-left: 0px;
}

table td {
  padding: 5px;
}

table td label {
  position: relative;
}

input[type='radio'] + span {
  margin-left: 4px;
}

.greyHeader {
  padding: 24px;
  background-color: #a5a5a5;
  color: white;
}

.greyHeader .title {
  font-size: 20px;
}

.greyHeader .subtitle {
  font-size: 14px;
  color: #ddd;
}

.greyHeader .logo {
  float: left;
  padding-right: 20px;
  box-sizing: content-box;
  width: 60px;
  height: 60px;
}

.greyHeader .logo img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  border-radius: 5px;
}

.icon {
  width: 20px;
  height: 20px;
  position: relative;
  top: -2px;
}

.statusButton.text {
  height: 32px;
  width: auto;
  line-height: 32px;
  display: inline-block;
  width: 100px;
  border-radius: 3px;
  cursor: pointer;
}

.statusButton.text .statusMarker {
  width: 26px;
  height: 26px;
  top: 3px;
  border-radius: 2px;
}

.statusButton.text.inactive {
  padding-left: 42px;
  padding-right: 10px;
  color: $grey;
}

.statusButton.text.inactive .statusMarker {
  left: 3px;
}

.statusButton.text.active {
  padding-right: 32px;
  padding-left: 15px;
  color: white;
}

.statusButton.text.active .statusMarker {
  right: 3px;
}

.statusButton {
  height: 16px;
  width: 32px;

  background-color: $secondary-color;
  border-radius: 2px;
  position: relative;
}

.statusButton .statusMarker {
  width: 14px;
  height: 12px;
  border-radius: 1px;
  position: absolute;
  background-color: white;
  top: 2px;
  cursor: pointer;
}

.statusButton.inactive {
  background-color: #e3e3e3;
}

.statusButton.active .statusMarker {
  right: 2px;
}

.statusButton.inactive .statusMarker {
  left: 2px;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    background-color: white;
  }

  :global .not-printable {
    display: none;
  }

  :global .printable {
    display: block;
  }
}

.Select-item {
  color: $secondary-color;
  border-color: $secondary-color;
}

.Select-item-icon {
  padding: 4px 5px;
  border-color: $secondary-color;
}

/** OVER BOOTSTRAP **/
body label {
  display: inline-block;
  margin-bottom: 0;
  font-weight: inherit;
}

body .label {
  font-size: inherit;
  font-weight: initial;
  color: inherit;
  text-align: inherit;
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  padding: 0;
  line-height: inherit;
}

table {
  table-layout: initial;
}

body .popover {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
}

h2 {
  font-size: 1.5em;
  font-weight: bold;
}

.top-spacing {
  margin-top: 25px;
}

.top-spacing-sm {
  margin-top: 10px;
}

.top-spacing-xs {
  margin-top: 5px;
}

.inline-block {
  display: inline-block;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
}

.tooltip.top .tooltip-arrow {
  bottom: 0px;
}

.tooltip-inner {
  padding: 18px;
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  border-radius: 0px;
  background: $dark-grey;
  box-shadow: 0px 8px 16px rgba($dark-grey, 0.2);
  font-size: 14px;
  line-height: 18px;
  word-break: break-word;
  max-width: 300px;
}

.tt-medium .tooltip-inner {
  max-width: 200px;
}

.tooltip.in {
  opacity: 1;
}

.tooltip.top .tooltip-arrow {
  border-top-color: $dark-grey;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: $dark-grey;
}

.tooltip.right .tooltip-arrow {
  border-right-color: $dark-grey;
}

.tooltip.left .tooltip-arrow {
  border-left-color: $dark-grey;
}

.danger-tooltip .tooltip-inner {
  background: $secondary-color;
}

.danger-tooltip.tooltip.top .tooltip-arrow {
  border-top-color: $secondary-color;
}

.danger-tooltip.tooltip.bottom .tooltip-arrow {
  border-bottom-color: $secondary-color;
}

.danger-tooltip.tooltip.right .tooltip-arrow {
  border-right-color: $secondary-color;
}

.danger-tooltip.tooltip.left .tooltip-arrow {
  border-left-color: $secondary-color;
}

.hide-text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

.text-left {
  text-align: left;
}

*.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}

.slice-main {
  color: $primary-color;
}

.slice-secondary {
  color: $secondary-color;
}

.slice-yellow {
  color: $tertiary-color;
}

.full-height {
  height: 100%;
}

.wrapper {
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 50%;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  background-color: $primary-color;
}

.click-to-copy {
  cursor: pointer;
}

.click-to-copy::after {
  content: '\e224';
  visibility: hidden;
  margin-left: 3px;
  font-family: 'Glyphicons Halflings';
  display: inline-block;
  position: relative;
}

.click-to-copy:hover::after {
  visibility: visible;
}

.portal-tabs .nav-tabs a {
  color: gray;
  font-size: 14px;
}

.portal-tabs .nav-tabs > li > a {
  border-style: none;
  border-color: transparent;
}

.portal-tabs .nav-tabs > li {
  margin-bottom: -2px;
}

.portal-tabs .nav-tabs > li.active > a,
.portal-tabs .nav-tabs > li > a.active {
  background-color: transparent !important;
  border-color: transparent;
}

.portal-tabs .nav-tabs > li.active > a,
.portal-tabs .nav-tabs > li.active > a:focus,
.portal-tabs .nav-tabs > li.active > a:hover,
.portal-tabs .nav-tabs > li > a.active,
.portal-tabs .nav-tabs > li > a.active:focus,
.portal-tabs .nav-tabs > li > a.active:hover {
  background-color: inherit;
  border-color: transparent;
  color: $dark-grey;
  font-weight: bold;
  border: none;
  border-bottom: 4px solid $primary-color;
  text-decoration: none;
  outline: none;
}

.portal-tabs .nav-tabs > li > a:focus,
.portal-tabs .nav-tabs > li > a:hover {
  background-color: inherit;
  border-color: transparent;
  text-decoration: none;
  outline: none;
}

.portal-tabs .nav-tabs > li > a:hover {
  color: $dark-grey;
  border-bottom: 4px solid $primary-color;
}

.s-alert-box {
  z-index: 1010 !important;
}

.ReactVirtualized__Grid__innerScrollContainer,
.ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
  outline: none;
}

mark.highlight-text {
  font-weight: bold;
  background: transparent;
}

.tooltip-inner mark.highlight-text {
  color: white;
}

.breadcrumb > li + li:before {
  padding: 0 5px;
  color: inherit;
  content: '>';
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.text-color-red {
  color: $red-color;
}
.text-color-yellow {
  color: $yellow-color;
}
.text-color-blue {
  color: $blue-color;
}
.text-color-green {
  color: $green-color;
}
.text-color-grey {
  color: $grey;
}

.Z2-shadow {
  box-shadow: 0 8px 16px rgba($dark-grey, 0.2);
}

.padding-rigt-standard {
  padding-right: 5px;
}
