@import "ri-global.scss";
.dm-header,
#dm-tabs-wrapper .portal-tabs,
#dm-tabs-wrapper .tab-content {
  padding-left: 10%;
  padding-right: 10%;
}

/* header starts */
.page-title {
  font-size: 24px;
  line-height: 30px;
  height: 30px;
  margin-bottom: 18px;
  font-weight: 300;
}

.dm-header {
  padding-top: 48px;
}

.dm-header .breadcrumb {
  background-color: transparent;
  margin-bottom: 14px;
  line-height: 18px;
  font-size: 14px;
}

.dm-header .breadcrumb a,
.dm-header .breadcrumb a:hover {
  color: inherit;
}

.dm-header .breadcrumb .bread-crumb-separator {
  padding: 0px 6px 0px 6px;
}

.dm-header .breadcrumb .glyphicon {
  width: 20px;
}

.dm-header .breadcrumb > li + li:before {
  padding: 0 18px;
}

.dm-header .folder-close-icon {
  margin-right: 6px;
  margin-bottom: -2px;
}

.dm-header .contract-icon {
  height: 16px;
  margin-right: 2px;
  margin-bottom: -2px;
}

.dm-header .deliverable-icon {
  height: 16px;
  margin-right: 2px;
  margin-bottom: -2px;
}

.dm-header .search-input {
  max-width: 300px;
  height: 45px;
}

.dm-header .search-dropdown-popover {
  display: none;
}

@keyframes ccd-search-dropdown {
  from {
    width: 302px;
  }
  to {
    width: 338px;
  }
}

.dm-header .searchBoxOpen + .search-dropdown-popover {
  display: block;
  width: 338px;
  animation: ccd-search-dropdown 200ms ease-in-out;
}
.dm-header .search-dropdown-popover .result-group {
  margin-bottom: 6px;
}
.dm-header .search-dropdown-popover .result-group-name {
  font-weight: bold;
  line-height: 12px;
  padding: 3px 12px;
  text-transform: uppercase;
  color: #999999;
  font-size: 12px;
}

.dm-header .search-dropdown-popover li.inactive {
  color: #bbbbbb;
}
.dm-header .search-dropdown-popover li.inactive mark {
  color: #aaaaaa;
}

.dm-header .search-dropdown-popover li {
  padding: 6px 18px;
  cursor: pointer;
}

.dm-header .search-dropdown-popover li:hover {
  background: #f5f5f5;
}

.dm-header .search-dropdown-popover li.selected mark {
  color: white;
}

.dm-header .search-dropdown-popover li.selected {
  color: white;
  background: $primary-color;
}

.dm-header .search-input input {
  width: 300px;
  font-size: 14px;
  line-height: 18px;
}
/* header ends */

/* tabs, tabs wrapper, tab content starts */
#dm-tabs-wrapper {
  margin-bottom: -70px;
}
#dm-tabs-wrapper .tab-content {
  background-color: white;
  min-height: 80vh;
  padding-top: 30px;
  padding-bottom: 200px;
  box-shadow: 0 -2px 24px 0 rgba($dark-grey, 0.1);
}

#dm-tabs-wrapper .nav-tabs {
  border-bottom: none;
}

#dm-tabs-wrapper .react-bs-table-container {
  margin-top: 24px;
}

#dm-tabs-wrapper .react-bs-table-container + .pagination-wrapper {
  margin: 18px auto;
  text-align: center;
}

.badge {
  border-radius: 20px;
  background-color: #bebebe;
  font-size: 10px;
  line-height: 12px;
}

.tab:hover .badge,
.tab.active .badge {
  background-color: $grey;
}
/* tabs, tabs wrapper, tab content starts */

/* Clients table starts */
.sb-table .folder-close-icon {
  position: absolute;
}

.sb-table .folder-close-icon + a.filter-route-link {
  margin-left: 30px;
}

.sb-table .table > thead > tr > th.client-cell.freeze-left,
.sb-table .table > tbody > tr > td.client-cell.freeze-left {
  width: 250px;
  padding-right: 30px;
}
/* Clients table ends */

/* Form modal starts */

.contract-edit-modal.modal-dialog,
.client-edit-modal.modal-dialog {
  width: 512px;
}

.form-modal .modal-body {
  margin-bottom: 0px;
}

.step-panel {
  max-width: 1024px;
}
.step-panel .step-form {
  max-width: 600px;
  margin-left: 28px;
}

#upload-file-button {
  padding: 0px;
  color: $grey;
  border-color: $grey;
  border-radius: 0px;
  margin-left: 12px;
  margin-top: 6px;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  width: 18px;
  height: 18px;
}
#upload-file-button .glyphicon {
  vertical-align: text-top;
}
/* Form modal ends */

/* Contracts table starts */
.sb-table .contract-icon {
  position: absolute;
}

.sb-table .contract-icon + a.filter-route-link {
  margin-left: 30px;
}

.sb-table .table > thead > tr > th.contract-cell.freeze-left,
.sb-table .table > tbody > tr > td.contract-cell.freeze-left {
  width: 300px;
  padding-right: 30px;
}
/* Contracts table ends */

/* Deliverables table starts */
.sb-table .deliverable-icon {
  position: absolute;
}

.sb-table .deliverable-icon + a.filter-route-link {
  margin-left: 30px;
}

.sb-table .table > thead > tr > th.deliverable-cell.freeze-left,
.sb-table .table > tbody > tr > td.deliverable-cell.freeze-left {
  width: 350px;
  padding-right: 30px;
}
/* Deliverables table ends */

/* Table Action Popover starts */
.sb-popover .popover-content {
  padding: 6px 0px;
}

.sb-popover .popover-content .action-button {
  cursor: pointer;
  padding: 0px 12px;
  line-height: 28px;
  font-size: 14px;
}

.sb-popover .popover-content .action-button:hover {
  background-color: #f5f5f5;
}
/* Table Action Popover ends */

.dm-beta-alert {
  margin-top: 4px;
  margin-left: 4px;
  margin-right: 4px;
}

/* @TODO: find a better way to align alert icon */
.wrap-alert-icon {
  position: relative;
  margin-right: 20px;
}

span.alert-icon {
  position: absolute;
  right: -22px;
  top: -4px;
}
span.alert-icon svg {
  position: relative;
}

.field-name-info-icon {
  margin-left: 6px;
  float: right;
}

.category-jobs-table .sb-table td .category-job-status,
.sb-form .table-cell.category-job-status {
  &.scheduled {
    color: $grey;
    font-weight: bold;
  }
  &.unscheduled {
    color: $grey;
    font-weight: bold;
  }
  &.processing {
    color: $dark-grey;
    font-weight: bold;
  }
  &.ready {
    color: lightgreen;
    font-weight: bold;
  }
  &.published {
    color: $primary-color;
    font-weight: bold;
  }
  &> .delayed,
  &.delayed {
    color: $secondary-color;
    font-size: 10px;
  }
  &.deleted {
    text-decoration: line-through;
  }
}

.category-jobs-table .sb-table td .to-be-preponed {
  color: $secondary-color
}
