@import "ri-global.scss";
.query-header {
  min-height: 110px;
  padding: 5px;
  width: 100%;
  .qb-selected-group,
  .qb-selected-query {
    position: relative;
    word-break: break-word;
    display: inline-block;
    padding: 0px 0px;
    font-size: 16px;
    line-height: 1.6;
    border-radius: 3px;
    color: black;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    vertical-align: middle;
  }

  .qb-selected-query {
    vertical-align: top;
  }
  .qb-group-name,
  .qb-query-name {
    color: gray;
    font-size: 13px;
  }
  .qb-query-name {
    vertical-align: top;
    padding-bottom: 3px;
    display: inline-block;
  }

  .btn-link {
    color: $grey;
    &:hover,
    &:focus {
      color: $font-color;
      text-decoration: none;
      font-weight: bold;
    }
  }
}

.search-filters-container {
  padding-bottom: 0.5em;

  > .btn-group {
    width: 100%;
    > .btn {
      width: 100%;
      text-align: left;
      > .caret {
        float: right;
        margin-top: 4%;
      }
    }
  }

  ul.dropdown-menu {
    width: 100%;
  }
}

.search-filter {
  margin: 0px 5px 0px 5px;
  > .filter {
    margin-bottom: 2px;
    height: 20px;
    > .label {
      font-size: 14px;
      font-weight: normal;
    }
  }
}

.search-filter-label {
  font-size: 14px;
  font-weight: normal;
}

.label-capitalize {
  text-transform: capitalize;
}
