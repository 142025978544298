@import "ri-global.scss";
.ReportsMenuList {
  min-width: 0px !important;
  max-height: 210px;
  overflow-y: auto;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
}

.ReportsMenuList > li > span,
.ReportsMenuList > li > a {
  display: block;
  padding: 6px 12px 3px 12px;
  clear: both;
  font-weight: normal;
  color: $dark-grey;
  white-space: nowrap;
  font-size: 14px
}

.ReportsMenuList > li > span:hover,
.ReportsMenuList > li > a:hover {
  text-decoration: none;
  background-color: $background-grey;
}

.no-result-li {
  padding-left: 6px;
  cursor: default;
}
