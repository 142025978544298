@import "ri-global.scss";
.json-edit-field {
  font-size: 14px;
  display: inline-block;

  .glyphicon-plus-sign,
  .glyphicon-minus,
  .glyphicon-ok-sign
  {
    margin: 3px 0 0 7px;
    font-size: medium;
  }


  .glyphicon-plus {
    font-size: medium;
  }

  .json-edit-field-textarea {
    width: 100%;
    font-size: 16px;
  }

  .json-edit-field-property {
    display: flex;

    padding: 5px 0;
    border-bottom: 1px dotted $light-grey;

    .json-edit-field-property-key {
      margin-top: 3px;
      min-width: 100px;
    }

    .disabled {
      color: $light-grey
    }
  }

  .json-edit-field-new-property {
    padding: 6px 0;
  }

  input {
    min-width: 200px
  }

.json-edit-field-toggle-raw {
  font-size: 11px;
  margin: 3px 0;
  .glyphicon {
    margin-left: 5px;
    font-size: 10px;
  }
}

}

.json-edit-sub-field {
  margin: 25px 0 0 -55px;
}
