@import "ri-global.scss";
.category-tests-page {
  .disabled-glyphicon {
    color: $light-grey;
  }
}

.category-tests-modal-form-body {
  max-height: calc(100vh - 280px);
  overflow-y: auto;
  overflow-x: hidden;
}

.category-tests-modal-icon-warn {
  color: $grey-color;
  margin: 10px 0 0 10px;
}

.category-tests-modal-section {
  margin: 5px 0 20px 0;
}

.category-tests-modal-form-error {
  margin: 1px 15px 0 0;
  line-height: 16px;
}

.category-tests-modal-reset-container {
  height: 50px;
}

.category-tests-no-padding {
  padding: 0 !important;
}

.category-test-tooltip-code {
  background-color: $grey;
  color: $background-grey;
  border-radius: 2px;
}

.category-test-run-all {
  line-height: 34px;
}
