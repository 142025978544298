@import "ri-global.scss";
.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: $primary-color;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: -5px;
  right: -5px;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAA20lEQVRo3u3asQkCQRCF4dk75CJzIw9bsAG7sQvlDMREExsQI8EqLMAejOR6ONAxkwv0ECOd90+4bPLxHsMGm0xkVsO1m5klJayZWR4ZenTPx7v+vX2WFFIND36HDQdeTrbz7NIsuu4khVRDgSv3rCg3t0/vJ4VU25OZ2AAGDBgwYMCAAQMGDBgwYMCAAQMGDBgwYMCAAQMGDBgwYMCAAQMG/HPT+W3pm29BfwuOiKXS0dN9mfDUzz2pLT0qT41MpSNXmaWlkO4z4Wp2GEg9LYv9tZaptEqVZZfWA2g1L/Nc5QMtAAAAAElFTkSuQmCC');
  background-position: bottom right;
  background-size: 20px;
  margin: 0px 3px 3px 0px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
  transition: transform 200ms ease;
}

.react-grid-item:hover > .react-resizable-handle {
  transform: scale(1.5, 1.5);
}

.react-grid-layout.locked {
  background: transparent;
}

.react-grid-layout.unlocked {
  background: #eee;
}

.react-grid-item {
  box-sizing: border-box;
}
.react-grid-item:not(.react-grid-placeholder) {
  background: white;
  border-radius: 0px 0px 0px 0px;
  box-shadow: 0px 4px 8px rgba($dark-grey, 0.2);
}
.react-grid-item.resizing {
  opacity: 0.9;
}
.react-grid-item.static {
  background: #cce;
}
.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}
.react-grid-item .minMax {
  font-size: 12px;
}
.react-grid-item .add {
  cursor: pointer;
}
.react-grid-dragHandleExample {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.edit-nlayout {
  z-index: 10;
  position: relative;
  margin-top: -37px;
  margin-right: 15px;
  padding: 4px 10px;
}

.index-label {
  border-radius: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -33px;
  margin-right: 60px;
  font-size: 16px;
  color: white;
  line-height: 18px;
}

.index-label-scorecard {
  background-color: $primary-color;
}

.index-label-nonscorecard {
  background-color: $secondary-color;
}

.index-label-category {
  margin-top: 0px;
  background-color: $grey;
}

.chart-loader-container {
  position: absolute;
  margin-left: 250px;
}

.qc-toolbar > div:first-child {
  padding: 0px;
}
.qc-toolbar > div:last-child {
  padding-right: 0px;
}

.qc-toolbar .qc-toolbar-date-range-filter {
  padding-left: 10px;
}
