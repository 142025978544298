@import "ri-global.scss";
.form-container {
  padding-top: 20px;
}


.greyHeader .userTitle {
  font-size: 20px;
}

.greyHeader .userInfo > div + div {
  margin-top: 5px;
}

.userWelcome {
  padding: 0px 0px 50px 0px;
}

.userWelcome > div {
  font-size: 40px;
  color: $primary-color;
}

.userWelcome > p {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 20px;
}

.activeBadge {
  height: 50px;
  line-height: 50px;
  background-color: $primary-color;
  color: white;
  padding-left: 20px;
  padding-right: 65px;
  font-size: bold;
  font-size: 16px;
  border-radius: 5px;
  position: relative;
}

.activeBadge.inactive {
  background-color: #E3E3E3;
  color: #999;
  padding-left: 65px;
  padding-right: 20px;
}

.activeBadge.inactive span {
  left: 5px;
}

.activeBadge span {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 5px;
  right: 5px;
  display: block;
  background-color: white;
  border-radius: 5px;
  transition: left .2s;
}

.close-button {
  margin-left: 5px
}
