@import "ri-global.scss";
.dropDownButton {
  cursor: pointer;
  margin-right: 20px;
}

.marginXL {
  right: 18px;
}

.profilePadding {
  margin-left: -7px;
}
