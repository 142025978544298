@import "ri-global.scss";

.react-bs-table-pagination button#pageDropDown {
  margin-left: 10px;
}
.react-bs-table-pagination .row {
  margin-right: 0px;
  margin-left: 0px;
}
.splitBy-chart-wrapper {
  position: relative;
}

.drill-down-button {
  position: absolute;
  right: 0;
  visibility: hidden;
  top: 0px;
  z-index: 2;
  opacity: 0;
  border-radius: 0px 0px 0px 5px;
  background: rgba(black, 0.5);
  color: $light-grey;
  transition: opacity 300ms ease-in-out;
}


.splitBy-chart-wrapper:hover .drill-down-button, .drill-down-button:hover, .drill-down-button:active, .drill-down-button:focus {
  visibility: visible;
  opacity: 1;
  color: white;
  text-decoration: none;
  background: rgba(black, 0.5);
}
.drill-down-button:hover {
  background: rgba(black, 0.7);
}
.drill-down-button:active, .drill-down-button:active:hover, .drill-down-button:focus:hover {
  background: rgba(black, 0.3);
}
.reset-zoom-button button {
  border: none !important;
  background-color: transparent;
  font-size: 20px;
}
.reset-zoom-button {
  position: absolute;
  z-index: 2;
  bottom: 5px;
  left: 5px;
  font-size: 15px;
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.search-qc-statusButton {
  height: 13px;
  width: 35px;
  background-color: $secondary-color;
  border-radius: 10px;
  position: relative;
}

.search-qc-statusButton .statusMarker {
  width: 18px;
  height: 18px;
  border-radius: 20px;
  position: absolute;
  background-color: $light-primary-color;
  top: -3px;
  cursor: pointer;
}

.search-qc-statusButton.inactive {
  background-color: darkgrey;
}

.search-qc-statusButton.active .statusMarker {
  right: -2px;
}

.search-qc-statusButton.inactive .statusMarker {
  left: -2px;
  background-color: white;
}

.qctool-widget [data-highcharts-chart] text, .qctool-widget [data-highcharts-chart] tspan {
  text-transform: capitalize;
}
.qctool-widget [data-highcharts-chart] .highcharts-tooltip text, .qctool-widget [data-highcharts-chart] .highcharts-tooltip tspan {
  text-transform: none;
}

.splitby-option-button {
  width: 145px;
}

.qc-option-button {
  width: 95px;
}

.qc-drilldown {
  font-size: 14px;
  padding: 0px;
  flex-grow: 1;
}

.qc-drilldownBy {
  width: 100%;
  display: flex;
}

.qc-drilldownBy .drilldown-label{
  padding: 0px 10px;
}

.qc-drilldown .bootstrap-typeahead-input {
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.qc-drilldown .dropdown-menu {
  width: 100%;
}

.qc-wrapper > .row > div
{
  border: 0px solid #dddddd !important;
  padding: 15px;
}

.drilldown-chart-container, .splitBy-chart-container {
  margin: 20px 0px;
  box-shadow: 0px 0px 5px rgba(black,0.3);
  position: relative;
  background: white;
}

.drilldown-chart-container .stock-chart-container .last-refresh,
.splitBy-chart-wrapper .stock-chart-container .last-refresh {
  margin-top: 3px;
  color: #ff564a;
  border: none;
  background-color: inherit;
}

.drilldown-chart-container .stock-chart-container .current-refresh,
.splitBy-chart-wrapper .stock-chart-container .current-refresh {
  margin-top: 3px;
  margin-right: 10px;
  color: #00b0bf;
  border: none;
  background-color: inherit;
}

.drilldown-chart-container .stock-chart-container .search-qc-statusButton,
.splitBy-chart-wrapper .stock-chart-container .search-qc-statusButton {
  margin-right: 15px;
  margin-top: 10px;
}

.drilldown-chart-container .stock-chart-container .refresh-graph-icon {
  float: right;
  margin-right: 16px;
  margin-top: 6px;
  visibility: hidden;
}

.drilldown-chart-container.disable-drilldown {
  background-color: #DDDDDD;
}

.splitBy-chart-wrapper .stock-chart-container .refresh-graph-icon {
  float: right;
  margin-right: 120px;
  margin-top: 6px;
  visibility: hidden;
}

.drilldown-chart-container .stock-chart-container {
  height: 281px;
}

.stock-chart-container:hover .refresh-graph-icon {
  visibility: visible;
}

.refresh-graph-icon .glyphicon.glyphicon-refresh {
  font-size: 16px;
}

.qc-default-title {
  margin-top: 100px;
  font-size: 18px;
  position: absolute;
  margin-left: 40%;
}

.qc-split-by-chart .left-charts-container {
  max-height: 2430px;
  overflow-y: auto;
  overflow-x: hidden;
}

.qc-description-counts {
  display: flex;
}

.qc-description-counts .radio {
  margin-top: 0;
  display: flex;
  flex-grow: 1;
}

.qc-description-counts .radio.disabled {
  color: #cccccc;
}

.qc-description-table .react-bs-table table td {
  white-space: normal;
}
