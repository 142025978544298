@import "ri-global.scss";
.checkbox-filter {
  position: relative;
  display: inline-block;
}

.checkbox-filter .warning {
  padding: 10px;
  margin-top: 10px;
}

.checkbox-filter .filter-popup {
  position: absolute;
  background-color: white;
  margin-top: 0px;
  padding: 18px;
  max-width: 700px;
  min-width: 364px;
  min-height: 140px;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: 0em;
  border: 1px solid $primary-color;
  position: absolute;
  z-index: 10;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: slideup;
  animation-name: slideup;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  margin-right: 12px;
}

.checkbox-filter .filter-popup .item {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  -webkit-box-flex: 22%;
  -webkit-flex: 22%;
  -ms-flex: 22%;
  flex: 22%;
}

.CheckBox {
  height: 16px;
  border-radius: 3px;
  cursor: pointer;
}

.ArrowRotationStop > svg {
  transform: rotate(0deg);
  transition: 400ms transform;
}

.ArrowRotationStart > svg {
  animation: spin 400ms;
  transform: rotate(90deg);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(90deg);
  }
}

.chbxlist.vertical-value-list {
  max-height: 260px;
  margin-top: 10px;
}

.chbxlist.paginated-value-list {
  max-height: 300px;
  width: 662px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: auto;
}

/* Only on IE 10+ */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .chbxlist.paginated-value-list {
    height: 300px;
  }
}

.paginated-value-list .value-box {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: block;
}

.value-box.columns-1 {
  width: 100%;
}

.value-box.columns-2 {
  width: 50%;
}

.value-box.columns-3 {
  width: 33.33%;
}

.value-box.columns-4 {
  width: 25%;
}

.value-box.columns-5 {
  width: 20%;
}

.select_buttons {
  margin-top: 12px;
  margin-right: 15px;
  display: inline-block;
}

.checkbox-filter .bold {
  font-weight: bold;
}

.search-input-box {
  max-width: 200px;
  line-height: 16px;
  border: none;
  position: relative;
}

.search-input-box .search-icon {
  position: absolute;
  left: 0px;
  top: 5px;
}

.search-input-box .close-icon-svg {
  position: absolute;
  right: 0px;
  top: 5px;
}

.search-input-box input {
  padding: 6px 20px 6px 24px;
  border-bottom: 1px solid #cccccc;
}

.search-input-box input:focus {
  border-bottom-color: $primary-color;
}

.MT0 {
  margin-top: 0px;
}

.MT10 {
  margin-top: 10px;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

.label-content {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.checkbox-filter label mark.highlight-text {
  background-color: transparent;
  color: inherit;
  padding: 0.1em;
}

.checkbox-filter.updated-by {
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

#dd-values-filter {
  width: 120px;
  font-size: 12px;
  float: right;
  margin-left: 6px;
}

#dd-values-filter .dropdown-button {
  width: auto;
  height: 27px;
  padding: 6px 12px 6px 12px;
  line-height: 14px;
}

#dd-values-filter.dropdown-wrapper .dropdown-button,
#dd-values-filter.dropdown-wrapper .dropdown-button:hover {
  border-bottom-width: 1px;
}
#dd-values-filter.dropdown-wrapper .dropdown-options {
  width: 120px;
  box-shadow: 0 8px 16px 0 rgba($dark-grey, 0.2);
  padding: 3px 0px;
}

#dd-values-filter.dropdown-wrapper .dropdown-options .dropdown-option {
  padding: 3px 12px;
  padding-left: 12px;
}

#dd-values-filter.borderNormal:hover {
  border-width: 1px;
  border-bottom-width: 0px;
}

#dd-values-filter.borderSliceTeal {
  border-bottom-width: 0px;
}

.checkbox-filter .pagination-wrapper {
  width: 120px;
  margin: auto;
  font-size: 12px;
  line-height: 14px;
  color: $dark-grey;
}

.checkbox-filter .filterSelector.before-calc {
  width: auto;
  max-width: 140px;
  padding-right: 1px;
}
