@import "ri-global.scss";

#search-tabs.tab-label {
  margin-top: -20px;
}

#search-tabs.tab-label > ul.nav-tabs {
  margin-left: 65px;
  margin-right: 20px;
}
