@import "ri-global.scss";
.sb-table {
  position: relative;
  width: auto;
  margin: 0 auto;

  .bool-cell-circle {
    margin-top: 3px;
    margin-left: auto;
    margin-right: auto;

    width: 8px;
    height: 8px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: $grey;
  }
}

.sb-table .table > thead > tr > th {
  border-bottom: 1px solid black;
  color: $grey;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;

  // header text
  .header-text {
    display: inline-block;
    overflow: hidden;
    vertical-align: text-bottom;
  }

  // if we have sorting, arrows let's leave space for it
  .header-with-sort {
    max-width: calc(100% - 21px);
  }
}

.sb-table .table > thead > tr > th.sort-column {
  padding-right: 15px;
  position: relative;
}
.sb-table .table > thead > tr > th .sort-icon {
  visibility: hidden;
  position: absolute;
  right: 0px;
}

.sb-table .table > thead > tr > th.sort-column .sort-icon {
  visibility: visible;
}

.sb-table .table>tbody>tr>td,
.sb-table .table>tfoot>tr>td,
.sb-table .table>thead>tr>td {
  padding: 16px;
  border-color: $light-grey;
}

.sb-table .table>tbody>tr>th,
.sb-table .table>tfoot>tr>th,
.sb-table .table>thead>tr>th {
  padding: 8px;
}

.sb-table tr.inactive td {
  color: $grey;
  opacity: 0.7;
}

.sb-table .table-hover > tbody > tr:hover > td {
  background-color: #ebebeb;
}

.sb-table  .danger {
  color: $secondary-color;
}

.sb-table .bold {
  font-weight: bold;
}

.sb-table td .glyphicon-exclamation-sign {
  font-size: 16px;
  margin-left: 6px;
  line-height: 10px;
}

.sb-table .freeze-left,
.sb-table .table > thead > tr > th.freeze-left,
.sb-table .table > thead > tr > th.sort-column.freeze-left {
  position: absolute;
  position: sticky;
  background: white;
  z-index: 100;
}

.sb-table .freeze-left {
  left: 0px;
  border-right: 1px solid $light-grey;
}

.sb-popover {
  border: 1px solid $light-grey;
  border-radius: 0px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(black,0.2);
}

.sb-table a.filter-route-link {
  color: inherit;
}

.sb-table .action-wrapper {
  position: absolute;
  padding: 16px 9px;
  right: 0px;
  top: 0px;
  height: 100%;
}

.sb-table .action-wrapper svg {
  padding: 6px 0px;
  cursor: pointer;
}

.sb-table .sb-table-text-cell {
  overflow: hidden;
  white-space: normal;

  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-overflow: ellipsis;
}

.sb-table .sb-table-text-line-6-cell {
  @extend .sb-table-text-cell;
  -webkit-line-clamp: 6;
}

.sb-table .sb-table-action-cell {
  display: flex;
  justify-content: space-around;
  align-items: center;

  div:hover {
    cursor: pointer;
  }
}

// -------------------- //
//     Compact view     //
// -------------------- //

$padding-compact: 5px;

.sb-table-compact {
  // change default cell padding
  .table>tbody>tr>td,
  .table>tfoot>tr>td,
  .table>thead>tr>td {
    padding: $padding-compact;
  }

  // remove extra spaces from header
  .table>tbody>tr>th,
  .table>tfoot>tr>th,
  .table>thead>tr>th {
    padding: $padding-compact !important;
  }

  // table can scroll
  table {
    width: 100%;
    overflow-y: scroll;
  }

  tbody {
    overflow-y: scroll;
  }

  // minimize filter components
  thead th select.select-filter {
    padding: 6px 0 6px 1px;
  }

  thead th div.date-filter{
    justify-content: center;
  }

  thead th select.date-filter-comparator {
    padding: 6px 0;
    width: 36px !important;
  }

  thead th input.date-filter-input {
    padding: 6px 0 6px 1px;
    min-width: 100px !important;
  }

  thead th select.number-filter-comparator {
    padding: 6px 0;
    width: 36px !important;
  }

  thead th input.number-filter-input {
    padding: 6px 0;
    text-align: center;
    width: 100% !important;


    // disable input type=number arrows
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    // firefox
    -moz-appearance: textfield;
  }

  // add border and change style
  th {
    border: 1px solid lightgrey;
  }

  tr > td {
    border: 1px solid lightgray;
  }

}


