@import "ri-global.scss";
.welcomeModal {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 10001;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow-y: auto;
  align-items: center;
  background-color: rgba($dark-grey,0.9)
}

.welcome-modal-img {
  height: 400px;
  width: 700px;
}

.welcomeLayout {
  width: 710px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0;
}

.takeOurTourBoxed {
  margin-top: 1px;
  padding: 30px;
  width: 527px;
  color: black;
  text-align: center;
  background: white;
}

.takeOurTour {
  height: 70px;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.39;
  color: $font-color;
}

.btnLayout {
  height: 22px;
  width: 539px;
}

.btnTakeTour {
  width: 527px;
  height: 62px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
  color: white;
  background: $primary-color;
  border: 0px !important;
  margin-left: 6px;
}

.btnLayout :hover {
  background-image: linear-gradient(94deg, #98cfd7, #97c8dd);
  box-shadow: 0 4px 7px 0 rgba(181, 181, 181, 0.5);
}
